import { useToaster, Toaster } from "@twilio-paste/toast";
import { createContext, FC, useState } from "react";
import { ReactElementProps, ToasterContextType } from "../../interface";

export const ToasterContext = createContext<ToasterContextType>(null!);

export const ToasterProvider: FC<ReactElementProps> = ({
  children,
}: ReactElementProps) => {
  const toaster = useToaster();
  const [providerReady] = useState<boolean>(true);

  return (
    <ToasterContext.Provider value={{ providerReady, toaster }}>
      <Toaster {...toaster} />
      {children}
    </ToasterContext.Provider>
  );
};
