import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { AgencyVideos } from "@ciptex/nfcc";
import { Box } from "@twilio-paste/core/box";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { Button, AlertDialog, Flex } from "@twilio-paste/core";
import { DownloadIcon } from "@twilio-paste/icons/esm/DownloadIcon";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { DateTime } from "luxon";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useToasterContext } from "../../hooks/useToasterContext";
import { useNavigate } from "react-router-dom";
import { EditIcon } from "@twilio-paste/icons/esm/EditIcon";
import { HelpText } from "@twilio-paste/core/help-text";
import { DeleteIcon } from "@twilio-paste/icons/esm/DeleteIcon";
import { Label } from "@twilio-paste/core/label";
import { Select, Option } from "@twilio-paste/core/select";
import { FilterIcon } from "@twilio-paste/icons/esm/FilterIcon";
import { Stack } from "@twilio-paste/core/stack";

/*
TODOs:
- add in call to delete item
*/

export const AgencyVideoTable: FC = () => {
  const [agencyVideos, setAgencyVideos] = useState<any[]>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const { listAgencyVideo, deleteAgencyVideo } = useNFCCContext();
  const { appState } = useAppState();
  const { toaster } = useToasterContext();

  // confirmation modal state management
  const [toDelete, setToDelete] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = (agencyId: number, rowIndex: number) => {
    setToDelete({ agencyId, rowIndex });
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const navigate = useNavigate();

  const getDate = () => {
    const today = DateTime.local();
    const todayDate = today.toFormat("dd-MM-yyyy-HH-mm-ss");
    return todayDate;
  };

  const exportTableData = () => {
    if (agencyVideos) {
      const csvData = [
        ["Name", "Agency ID", "Agency Name", "Status"],
        ...agencyVideos.map(
          ({ name, agencyNetsuiteRef, agencyName, status }) => [
            name,
            agencyNetsuiteRef,
            agencyName,
            status,
          ],
        ),
      ];
      const csv = csvData.map((row) => row.join(",")).join("\n");
      const csvBlob = new Blob([csv], { type: "text/csv" });
      const csvUrl = URL.createObjectURL(csvBlob);
      const downloadLink = document.createElement("a");
      downloadLink.href = csvUrl;
      downloadLink.download = `agencyVideo-${getDate()}.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const data: AgencyVideos = await listAgencyVideo();
        if (data) {
          const filteredAV = data.filter(
            (a) => (a.status ?? "") != "Not enabled",
          );

          setAgencyVideos(filteredAV);

          setLoaded(true);
        }
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Could not retrieve video agency data",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    })();
  }, []);

  const handleDelete = async (agencyId: number, rowIndex: number) => {
    if (agencyVideos) {
      try {
        await deleteAgencyVideo(agencyId);
        toaster.push({
          message: "Agency video configuration deactivated successfully",
          variant: "success",
          dismissAfter: 4000,
        });

        const data: AgencyVideos = await listAgencyVideo();
        if (data) {
          const filteredAV = data.filter(
            (a) => (a.status ?? "") != "Not enabled",
          );

          setAgencyVideos(filteredAV);

          setLoaded(true);
        }
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Agency video configuration could not be deleted",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    }
  };

  const handleCloseWithDelete = () => {
    setIsOpen(false);
    handleDelete(toDelete.agencyId, toDelete.rowIndex);
    setToDelete({});
  };

  return (
    <Box width="100%">
      <HeaderTitleText titleText="Video Agencies" />
      <Box display="flex" justifyContent="flex-end" marginY="space60">
        <Flex>
          <Box
            display="flex"
            justifyContent="space-between"
            marginY="space60"
            alignItems="flex-end"
            columnGap="space60"
          >
            <Button variant="primary" onClick={() => navigate("add")}>
              <PlusIcon decorative={false} title="Add new service" />
              Add New Video Agency
            </Button>
            <Button variant="secondary" onClick={exportTableData}>
              Download CSV
              <DownloadIcon decorative={false} title="Download csv icon" />
            </Button>
          </Box>
        </Flex>
      </Box>

      <Table aria-label="Video agencies table" striped>
        <THead>
          <Tr>
            <Th>Actions</Th>
            <Th>Name</Th>
            <Th>Agency ID</Th>
            <Th>Agency Name</Th>
            <Th>Status</Th>
          </Tr>
        </THead>
        <TBody>
          {agencyVideos && loaded ? (
            agencyVideos &&
            agencyVideos.map((av: any, rowIndex: number) => (
              <Tr key={rowIndex}>
                <Td>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    flexDirection="row"
                    columnGap="space60"
                  >
                    <Stack orientation="vertical" spacing="space60">
                      <Button
                        variant="secondary"
                        onClick={() =>
                          navigate(`/agency-video/edit/${av.agencyId}`)
                        }
                      >
                        <EditIcon
                          decorative={false}
                          title="Edit Video Config"
                        />
                      </Button>
                      <Button
                        variant="destructive"
                        onClick={() => handleOpen(av.agencyId, rowIndex)}
                      >
                        <DeleteIcon
                          decorative={false}
                          title="Deactivate Video Config"
                        />
                      </Button>
                    </Stack>
                  </Box>
                </Td>
                <Td>{av.name}</Td>
                <Td>{(av as any).agencyNetsuiteRef}</Td>
                <Td>{(av as any).agencyName}</Td>
                <Td>{av.status}</Td>
              </Tr>
            ))
          ) : (
            <TableSkeletonLoader numberOfTd={5} numberOfTr={1} />
          )}
        </TBody>
      </Table>
      <AlertDialog
        heading="Deactivate Agency Video Configuration"
        isOpen={isOpen}
        destructive
        onConfirm={handleCloseWithDelete}
        onConfirmLabel="Delete"
        onDismiss={handleClose}
        onDismissLabel="Cancel"
      >
        Please confirm you want to deactivate this video configuration. This
        agency will no longer be able to receive connections via video.
      </AlertDialog>
    </Box>
  );
};
