// react imports
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// paste imports
import { FilterIcon } from "@twilio-paste/icons/esm/FilterIcon";
import { Button } from "@twilio-paste/core/button";
import { Select, Option } from "@twilio-paste/core/select";
import { Label } from "@twilio-paste/core/label";
import { Stack } from "@twilio-paste/core/stack";
import { DatePicker, formatReturnDate } from "@twilio-paste/core/date-picker";
import { Box } from "@twilio-paste/core/box";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { DownloadIcon } from "@twilio-paste/icons/esm/DownloadIcon";
import { HelpText } from "@twilio-paste/core/help-text";

// nfcc imports
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import {
  Agency,
  Services,
  ConnectionInsightsReport,
  User,
  Users,
  ConnectionInsights,
} from "@ciptex/nfcc";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useToasterContext } from "../../hooks/useToasterContext";

import { DateTime } from "luxon";

// chart setup***
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { Scroller } from "../Scroller/Scroller";

import { Alert } from "@twilio-paste/core";
import { timezones } from "../../constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const ConnectionInsightsPage: FC = () => {
  const { agencyId } = useParams();
  const [agency, setAgency] = useState<Agency>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [services, setServices] = useState<Services>();
  const [tz, setTz] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    getAgency,
    listServices,
    getInsightsForAgency,
    getInsightsReportForAgency,
    listUsersEmailFilter,
    getUser,
  } = useNFCCContext();
  const { appState } = useAppState();
  const { toaster } = useToasterContext();

  const options = {
    responsive: true,
  };

  // filters ***
  const [servicesFilter, setServicesFilter] = useState<string[]>();
  const [connectionTypesFilter, setConnectionTypesFilter] = useState<string[]>([
    "Phone",
    "Online",
    "Video",
  ]);
  const [languagesFilter, setLanguagesFilter] = useState<string[]>([
    "English",
    "Spanish",
  ]);

  const [servicesFilterValue, setServicesFilterValue] =
    useState<string>("All Services");
  const [connectionTypesFilterValue, setConnectionTypesFilterValue] =
    useState<string>("All Connection Types");
  const [languagesFilterValue, setLanguagesFilterValue] =
    useState<string>("All Languages");

  const [dateError, setDateError] = useState<string>("");
  const [preDateError, setPreDateError] = useState<string>("");

  const [filteredReportingData, setFilteredReportingData] = useState<any[]>();

  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const [reportData, setReportData] = useState<ConnectionInsightsReport>();

  const data = {
    labels: (reportData || { dates: [] }).dates,
    datasets: [
      {
        label: "Phone",
        data: (reportData || { phone: [] }).phone,
        backgroundColor: "#4b81c3",
      },
      {
        label: "Online",
        data: (reportData || { online: [] }).online,
        backgroundColor: "#150c39",
      },
      {
        label: "Video",
        data: ((reportData || { video: [] }) as any).video,
        backgroundColor: "#621F52",
      },
    ],
  };

  const tzs = timezones

  const applyFilters = async (e: any) => {
    if (filteredReportingData) {
      if (agency && startDate && endDate) {
        if (startDate > endDate) {
          setDateError("End date cannot be before start date");
          setPreDateError("Please fix error");
          setLoading(false);
        } else {
          setLoading(true);
          setDateError("");
          setPreDateError("");

          const startDateUTC = startDate + "T00:00:00+00:00";

          const endDateUTC = endDate + "T23:59:59+00:00";

          const startDateLocal = DateTime.fromISO(startDateUTC, {
            zone: tz,
          });

          const endDateLocal = DateTime.fromISO(endDateUTC, {
            zone: tz,
          });

          // get data for period
          const data = await getInsightsForAgency(
            agency.agencyId ?? 0,
            startDateLocal,
            endDateLocal,
          );

          let filteredCCIs: any[] = data;

          if (Object.keys(filteredCCIs).includes("error_message")) {
            filteredCCIs = [];
          }

          let ss = "";
          let ll = "";

          if (filteredCCIs) {
            if (servicesFilterValue !== "All Services") {
              filteredCCIs = filteredCCIs.filter(
                (s) => s.service === servicesFilterValue,
              );
              ss = servicesFilterValue;
            }

            if (languagesFilterValue !== "All Languages") {
              filteredCCIs = filteredCCIs.filter(
                (s) => s.language === languagesFilterValue,
              );
              ll = languagesFilterValue;
            }

            if (connectionTypesFilterValue !== "All Connection Types") {
              filteredCCIs = filteredCCIs.filter(
                (s) => s.connectionType === connectionTypesFilterValue,
              );
            }
          }

          setFilteredReportingData(filteredCCIs);

          if (filteredCCIs.length > 0) {
            const newData: ConnectionInsights = [];
            // For each in data do new dateTime and setZone
            for (const time of filteredCCIs) {
              const oldDT = time.endDateTime;
              const oldISO = oldDT?.replace(" ", "T").split(".")[0] + "+00:00";
              const changeDateTime = DateTime.fromISO(oldISO, {
                zone: tz,
              });
              time.endDateTime = changeDateTime.toFormat("dd MMM yyyy HH:mm");
              newData.push(time);
            }
            // setReportData(report);
            setFilteredReportingData(newData);

            setLoaded(true);
            // If data present then loading set to false so button not in loading state
            setLoading(false);
          } else {
            setLoaded(false);
            // If data not present then loading set to true so button in loading state
            setLoading(true);
            toaster.push({
              message: "No data found for this range",
              variant: "error",
              dismissAfter: 4000,
            });
          }

          if (reportData) {
            const report = await getInsightsReportForAgency(
              agency.agencyId || 0,
              startDate,
              endDate,
              ss,
              ll,
            );
            setReportData(report);
          }

          setLoading(false);
        }
      }
    }
    // if (filteredReportingData === undefined) {
    // 	setLoading(false);
    // 	toaster.push({
    // 		message: "No data to filter",
    // 		variant: "error",
    // 		dismissAfter: 4000
    // 	});
    // }
  };

  const handleFilterChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (filteredReportingData) {
      if (target.name === "service") {
        setServicesFilterValue(target.value);
        setLoading(false);
      } else if (target.name === "connectionType") {
        setConnectionTypesFilterValue(target.value);
        setLoading(false);
      } else if (target.name === "language") {
        setLanguagesFilterValue(target.value);
        setLoading(false);
      }
    }
  };

  const handleChange = (name: string, val: string, format: string) => {
    const d = formatReturnDate(val, format);

    if (name === "startDatePicker") {
      setStartDate(d);
    } else {
      setEndDate(d);
    }
  };

  const getReport = async () => {
    const d = new Date();
    const e = new Date();

    e.setDate(e.getDate() - 7);

    const report = await getInsightsReportForAgency(
      agency?.agencyId ?? 0,
      startDate ?? d.toISOString().substring(0, 10),
      endDate ?? e.toISOString().substring(0, 10),
      undefined,
      undefined,
    );

    setReportData(report);

    console.log("report", report);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const users: Users = await listUsersEmailFilter(
          encodeURI(appState.email),
        );
        const u: User = await getUser(users[0].userId ?? 0);
        const tz1 = (u.timezone as any)?.timezone ?? "EST";
        const tz = tzs.find((t) => t.name === tz1);
        setTz(tz?.tag);

        let agId: number;

        if (agencyId) {
          agId = parseInt(agencyId ? agencyId : "");
        } else {
          agId = appState.agencyId;
        }

        const data: Agency = await getAgency(agId);
        setAgency(data);

        const s = await listServices();
        setServices(s);

        setServicesFilter(s.map(({ name }) => name ?? ""));

        const d = new Date();

        const endDate = d.toISOString().substring(0, 10);

        setEndDate(endDate);

        d.setDate(d.getDate() - 7);

        const startDate = d.toISOString().substring(0, 10);

        setStartDate(startDate);

        // get reporting data
        const insights = await getInsightsForAgency(
          data.agencyId ?? 0,
          startDate,
          endDate,
        );

        setFilteredReportingData(insights);

        if ((insights as any).error_message) {
          console.error("no data");
          toaster.push({
            message: "Currently there is no data for this agency",
            variant: "neutral",
            dismissAfter: 4000,
          });
          setLoading(false);
        } else {
          setLoaded(true);
        }

        if (insights) {
          const newData: ConnectionInsights = [];
          // For each in data do new dateTime and setZone
          for (const time of insights) {
            const oldDT = time.endDateTime;
            const oldISO = oldDT?.replace(" ", "T").split(".")[0] + "+00:00";
            const changeDateTime = DateTime.fromISO(oldISO, {
              zone: tz?.tag,
            });
            // 2022-05-16 20:55:56.000000
            time.endDateTime = changeDateTime.toFormat("dd MMM yyyy HH:mm");
            newData.push(time);
          }
          //setReportData(report);
          setFilteredReportingData(newData);
          setLoading(false);
        }
        if (!insights) {
          console.error("data retrieval error");
          toaster.push({
            message:
              "There is an issue retrieving data in this range, please try again",
            variant: "error",
            dismissAfter: 4000,
          });
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Could not retrieve data",
          variant: "error",
          dismissAfter: 4000,
        });
        setLoading(false);
      }
    })();
  }, []);

  const getDate = () => {
    const today = DateTime.local();
    const todayDate = today.toFormat("dd-MM-yyyy-HH-mm-ss");
    return todayDate;
  };

  const exportTableData = () => {
    if (filteredReportingData) {
      const csvData = [
        [
          "Connection SID",
          "Language",
          "Connection Type",
          "Service",
          "State",
          "ZIP Code",
          "Client Phone Number",
          "Dialed Phone Number",
          "Destination",
          "Email",
          "Is Duplicate",
          "Timestamp",
          "Campaign",
          "Referrer",
        ],
        ...filteredReportingData.map(
          ({
            flowSid,
            language,
            connectionType,
            service,
            state,
            zipCode,
            fromPhone,
            toPhone,
            destinationPhone,
            fromEmail,
            duplicateCheckResult,
            endDateTime,
            campaign,
            referrer,
          }) => [
            flowSid,
            language,
            connectionType,
            service,
            state,
            zipCode,
            fromPhone,
            toPhone,
            destinationPhone,
            fromEmail,
            duplicateCheckResult,
            endDateTime,
            campaign,
            referrer,
          ],
        ),
      ];
      const csv = csvData.map((row) => row.join(",")).join("\n");
      const csvBlob = new Blob([csv], { type: "text/csv" });
      const csvUrl = URL.createObjectURL(csvBlob);
      const downloadLink = document.createElement("a");
      downloadLink.href = csvUrl;
      downloadLink.download = `connection-insights-${getDate()}.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <>
      <Box width="95%">
        <Box marginY="space60">
          <Alert variant={"neutral"}>
            Data is only available for the last 90 days through the portal. For
            data prior to this period, please reach out to mstout@nfcc.org
          </Alert>
        </Box>
        <HeaderTitleText titleText="Connection Insights" />

        <Box display="flex" justifyContent="flex-end" marginY="space60">
          <Button variant="secondary" onClick={getReport}>
            Show Report
          </Button>
        </Box>

        <Box display="flex" justifyContent="flex-end" marginY="space60">
          <Button variant="secondary" onClick={exportTableData}>
            Download CSV
            <DownloadIcon decorative={false} title="download csv icon" />
          </Button>
        </Box>

        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-start"
            marginY="space60"
          >
            <Box
              display="flex"
              flexDirection="row"
              columnGap="space60"
              alignItems="flex-start"
              flexWrap="wrap"
            >
              {/* TODO - Align items so on error the start date lines up with rest of filter - hacked for now with extra error message */}
              <Box display="flex" alignItems="flex-start" flexDirection="row">
                <Stack orientation="horizontal" spacing="space80">
                  <Box>
                    <Label htmlFor="startDatePicker">Start date</Label>
                    <DatePicker
                      hasError={preDateError.length > 0}
                      id="startDatePicker"
                      name="startDatePicker"
                      onChange={(evt) =>
                        handleChange(
                          evt.target.name,
                          evt.target.value,
                          "yyyy-MM-dd",
                        )
                      }
                      enterKeyHint={null!}
                      value={startDate}
                    />
                    {preDateError.length > 0 && (
                      <HelpText variant="error">{preDateError}</HelpText>
                    )}
                  </Box>
                  <Box>
                    <Label htmlFor="endDatePicker">End date</Label>
                    <DatePicker
                      hasError={dateError.length > 0}
                      id="endDatePicker"
                      name="endDatePicker"
                      onChange={(evt) =>
                        handleChange(
                          evt.target.name,
                          evt.target.value,
                          "yyyy-MM-dd",
                        )
                      }
                      enterKeyHint={null!}
                      value={endDate}
                    />
                    {dateError.length > 0 && (
                      <HelpText variant="error">{dateError}</HelpText>
                    )}
                  </Box>
                </Stack>
              </Box>

              <Box width="15vw">
                <Label htmlFor="services">Service</Label>
                <Select
                  id="services"
                  name="service"
                  value={servicesFilterValue}
                  onChange={handleFilterChange}
                >
                  <Option value="All Services" key="allServices">
                    All Services
                  </Option>
                  {servicesFilter &&
                    servicesFilter?.map((f: any, index: number) => (
                      <Option value={f} key={"serv-" + index}>
                        {f}
                      </Option>
                    ))}
                </Select>
              </Box>

              <Box width="15vw">
                <Label htmlFor="connectionType">Connection Type</Label>
                <Select
                  id="connectionType"
                  name="connectionType"
                  value={connectionTypesFilterValue}
                  onChange={handleFilterChange}
                >
                  <Option value="All Connection Types" key="allConnectionTypes">
                    All Connection Types
                  </Option>
                  {connectionTypesFilter &&
                    connectionTypesFilter?.map((f: any, index: number) => (
                      <Option value={f} key={"conn-" + index}>
                        {f}
                      </Option>
                    ))}
                </Select>
              </Box>

              <Box width="15vw">
                <Label htmlFor="language">Language</Label>
                <Select
                  id="language"
                  name="language"
                  value={languagesFilterValue}
                  onChange={handleFilterChange}
                >
                  <Option value="All Languages" key="allLanguages">
                    All Languages
                  </Option>
                  {languagesFilter &&
                    languagesFilter?.map((f: any, index: number) => (
                      <Option value={f} key={"lang-" + index}>
                        {f}
                      </Option>
                    ))}
                </Select>
              </Box>
            </Box>

            <Box
              display="flex"
              justifyContent="flex-end"
              height="fit-content"
              paddingTop="space70"
            >
              {loading ? (
                <Button variant="primary" loading>
                  Apply
                </Button>
              ) : (
                <Button variant="primary" onClick={applyFilters}>
                  <FilterIcon decorative={false} title="Apply filters" />
                  Apply
                </Button>
              )}
            </Box>
          </Box>
        </>

        <Scroller />

        <Box display="flex" width="50%" alignSelf="center" alignItems="center">
          {filteredReportingData && loaded && reportData ? (
            <Bar aria-label="insights-graph" options={options} data={data} />
          ) : (
            ""
          )}
        </Box>

        <Box display="flex" marginY="space60" alignSelf="center" width="100%">
          {filteredReportingData && loaded ? (
            <Table aria-label="Connection data table" striped>
              <THead>
                <Tr>
                  <Th>Connection SID</Th>
                  <Th>Language</Th>
                  <Th>Connection Type</Th>
                  <Th>Service</Th>
                  <Th>State</Th>
                  <Th>ZIP Code</Th>
                  <Th>Client Phone Number</Th>
                  <Th>Dialed Phone Number</Th>
                  <Th>Destination</Th>
                  <Th>Email</Th>
                  <Th>Is Duplicate</Th>
                  <Th>Timestamp</Th>
                  <Th>Campaign</Th>
                  <Th>Referrer</Th>
                </Tr>
              </THead>
              <TBody>
                {filteredReportingData && loaded ? (
                  filteredReportingData &&
                  filteredReportingData.map((f: any, rowIndex: number) => (
                    <Tr key={rowIndex}>
                      <Td>{f.flowSid}</Td>
                      <Td>{f.language}</Td>
                      <Td>{f.connectionType}</Td>
                      <Td>{f.service}</Td>
                      <Td>{f.state}</Td>
                      <Td>{f.zipCode}</Td>
                      <Td>{f.fromPhone}</Td>
                      <Td>{f.toPhone}</Td>
                      <Td>{f.destinationPhone}</Td>
                      <Td>{f.fromEmail}</Td>
                      <Td>{(f.duplicateCheckResult === 0 && "No") || "Yes"}</Td>
                      <Td>{f.endDateTime}</Td>
                      <Td>{f.campaign}</Td>
                      <Td>{f.referrer}</Td>
                    </Tr>
                  ))
                ) : (
                  <TableSkeletonLoader numberOfTr={12} numberOfTd={0} />
                )}
              </TBody>
            </Table>
          ) : (
            <Box
              display="flex"
              marginY="space60"
              alignSelf="center"
              width="100%"
            >
              <Table>
                <THead>
                  <Tr>
                    <Th>Connection SID</Th>
                    <Th>Language</Th>
                    <Th>Connection Type</Th>
                    <Th>Service</Th>
                    <Th>State</Th>
                    <Th>ZIP Code</Th>
                    <Th>Client Phone Number</Th>
                    <Th>Dialed Phone Number</Th>
                    <Th>Destination</Th>
                    <Th>Email</Th>
                    <Th>Is Duplicate</Th>
                    <Th>Timestamp</Th>
                    <Th>Campaign</Th>
                    <Th>Referrer</Th>
                  </Tr>
                </THead>
                <TBody>
                  <Tr>
                    <Td colSpan={12}>No data to display</Td>
                  </Tr>
                </TBody>
              </Table>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
