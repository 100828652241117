import { FC, useState } from "react";
import { Box } from "@twilio-paste/core/box";
import { StepperStage4 } from "./StepperStage4";
import { Heading } from "@twilio-paste/core/heading";
import { Text } from "@twilio-paste/core/text";
import { Button } from "@twilio-paste/core/button";
import { useNavigate, useParams } from "react-router-dom";
import { useUID } from "@twilio-paste/core/dist/uid-library";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalHeading,
} from "@twilio-paste/core/modal";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { useAppState } from "../../hooks/useAppState/useAppState";

import { useToasterContext } from "../../hooks/useToasterContext";
// import { Agency } from "@ciptex/nfcc";

export const Complete: FC = () => {
  const navigate = useNavigate();
  const { toaster } = useToasterContext();
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const modalHeadingID = useUID();
  const { appState } = useAppState();
  const { agencyId } = useParams();

  const { getAgency, updateAgency } = useNFCCContext();

  const handleGoLive = async (e: any) => {
    e.preventDefault();
    try {
      if (agencyId) {
        const ag = await getAgency(parseInt(agencyId));
        const newAg = { ...ag, isLive: 1, isLiveOnline: 1 };
        updateAgency(parseInt(agencyId), newAg);
      } else {
        const ag = await getAgency(appState.agencyId);
        const newAg = { ...ag, isLive: 1, isLiveOnline: 1 };
        updateAgency(appState.agencyId, newAg);
      }

      toaster.push({
        message: "Agency put live successfully!",
        variant: "success",
        dismissAfter: 4000,
      });
      if (appState.role === "nfcc_admin") {
        navigate("/agencies/" + agencyId + "/setup/success");
      } else {
        navigate("/setup/success");
      }
    } catch (error) {
      console.error(error);
      toaster.push({
        message: "Could not put agency live",
        variant: "error",
        dismissAfter: 4000,
      });
    }
  };

  const handleNoGoLive = async (e: any) => {
    e.preventDefault();
    try {
      if (agencyId) {
        const ag = await getAgency(parseInt(agencyId));
        const newAg = { ...ag, isLive: 0, isLiveOnline: 0 };
        updateAgency(parseInt(agencyId), newAg);
      } else {
        const ag = await getAgency(appState.agencyId);
        const newAg = { ...ag, isLive: 0, isLiveOnline: 0 };
        updateAgency(appState.agencyId, newAg);
      }

      if (appState.role === "nfcc_admin") {
        navigate("/agencies/" + agencyId + "/setup");
      } else {
        navigate("/setup");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <StepperStage4 />

      <Box
        paddingX="space200"
        paddingY="space100"
        display="flex"
        flexDirection="column"
      >
        <Heading as="h1" variant="heading10">
          Go Live
        </Heading>

        <Text as="p">You can edit services later if required.</Text>

        <Box
          display="flex"
          position="relative"
          paddingY="space100"
          columnGap="space100"
        >
          <Button variant="primary" onClick={handleNoGoLive}>
            Save for Later
          </Button>

          <Button variant="primary" onClick={handleOpen}>
            Go Live
          </Button>
        </Box>
      </Box>

      <Modal
        isOpen={isOpen}
        onDismiss={handleClose}
        size={"default"}
        ariaLabelledby={modalHeadingID}
      >
        <ModalHeader>
          <ModalHeading as="h2" id={modalHeadingID}>
            Confirm Go Live
          </ModalHeading>
        </ModalHeader>

        <ModalBody>
          <Text as="p">
            Are you sure you want to go live? You can edit settings later in the
            portal if required.
          </Text>
        </ModalBody>

        <ModalFooter>
          <ModalFooterActions>
            <Button variant="secondary" onClick={handleClose}>
              Back
            </Button>
            <Button variant="primary" onClick={handleGoLive}>
              Yes, Go Live
            </Button>
          </ModalFooterActions>
        </ModalFooter>
      </Modal>
    </>
  );
};
