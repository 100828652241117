import { FC } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Box } from "@twilio-paste/core/box";
import { Header } from "../Header/Header";
import { SideNav } from "../Sidenav/Sidenav";
import { PrivateRoute } from "../PrivateRoute/PrivateRoute";
import { HEADERS_HEIGHT, Role } from "../../constants";
import { BreadcrumbMaker } from "../BreadcrumbMaker/BreadcrumbMaker";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { Text } from "@twilio-paste/core/text";
import { ScreenReaderOnly } from "@twilio-paste/core";

export const MainContainer: FC = () => {
  const { appState } = useAppState();
  const { agencyId } = useParams();

  return (
    <PrivateRoute>
      <>
        <ScreenReaderOnly>
          <Text as="a" href="#main">
            Skip to main content
          </Text>
        </ScreenReaderOnly>
        <Header />
        <Box display="flex">
          {(appState.role === Role.client || agencyId) && <SideNav />}
          <Box
            as="main"
            id="main"
            padding={["space0", "space60", "space100"]}
            width={[`calc(100vw - ${HEADERS_HEIGHT}`, "100%", "100%"]}
          >
            {!location.pathname.split("/").includes("start") && (
              <BreadcrumbMaker />
            )}
            <Outlet />
          </Box>
        </Box>
      </>
    </PrivateRoute>
  );
};
