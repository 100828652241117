import { createContext, FC, useCallback, useState } from "react";
import { ReactElementProps } from "../../interface";
import { NFCCContextType } from "../../types/ciptex-sdk";
import {
  AgenciesNFCCView,
  Agency,
  NFCCClient,
  Audits,
  Services,
  Service,
  Categories,
  Agencies,
  ServicePhoneNumbers,
  ServiceNew,
  PhoneNumbers,
  PhoneNumber,
  User,
  Users,
  UserAuth,
  Templates,
  Timezone,
  State,
  AgencyServiceConfigNew,
  ConnectionInsights,
  ConnectionInsightsReport,
  AgencyServiceConfigs,
  AgencyServiceConfig,
  Holidays,
  AgencyAvailableServices,
  SetPasswordBody,
  ValidationResponse,
  Billing,
  HolidayNew,
  Holiday,
  AgencyVideo,
  AgencyVideos,
  AgencyVideoNew,
  VideoCampaigns,
  VideoCampaign,
  VideoCampaignNew,
  Chatbots,
  Chatbot,
  ChatbotNew,
} from "@ciptex/nfcc";

export const NFCCContext = createContext<NFCCContextType>(null!);

export const NFCCProvider: FC<ReactElementProps> = ({
  children,
}: ReactElementProps) => {
  const [nfccClient, setNfccClient] = useState<NFCCClient>();
  const [providerReady, setProviderReady] = useState<boolean>(false);

  const connect = useCallback((token: string) => {
    try {
      const client = new NFCCClient({ TOKEN: token });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.nfccClient = client;
      setNfccClient(client);
      setProviderReady(true);
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  const listTemplates = useCallback(async (): Promise<Templates> => {
    const templates = await nfccClient?.templates.getSendgridTemplates();
    if (templates) {
      return templates;
    } else {
      throw new Error("No templates");
    }
  }, [nfccClient]);

  const listAgenciesNFCCView =
    useCallback(async (): Promise<AgenciesNFCCView> => {
      const Agencies = await nfccClient?.agencynfccview.list();
      if (Agencies) {
        return Agencies;
      } else {
        throw new Error("No Agencies");
      }
    }, [nfccClient]);

  const listAgencyAudits = useCallback(
    async (agencyId: number): Promise<Audits> => {
      const audits = await nfccClient?.agencyaudit.list(agencyId);
      if (audits) {
        return audits;
      } else {
        throw new Error("No agency audits");
      }
    },
    [nfccClient],
  );

  const listAudits = useCallback(
    async (startDate: string, endDate: string): Promise<Audits> => {
      const audits = await nfccClient?.audit.list(startDate, endDate);
      if (audits) {
        return audits;
      } else {
        throw new Error("No audits");
      }
    },
    [nfccClient],
  );

  const listServices = useCallback(async (): Promise<Services> => {
    const services = await nfccClient?.srvc.list();
    if (services) {
      return services;
    } else {
      throw new Error("No services");
    }
  }, [nfccClient]);

  const listCategories = useCallback(async (): Promise<Categories> => {
    const categories = await nfccClient?.category.list();
    if (categories) {
      return categories;
    } else {
      throw new Error("No categories");
    }
  }, [nfccClient]);

  const listAgencies = useCallback(async (): Promise<Agencies> => {
    const agencies = await nfccClient?.agency.list();
    if (agencies) {
      return agencies;
    } else {
      throw new Error("No agencies");
    }
  }, [nfccClient]);

  const listPhoneNumbers = useCallback(async (): Promise<PhoneNumbers> => {
    const pns = await nfccClient?.phonenumber.list();
    if (pns) {
      return pns;
    } else {
      throw new Error("No phone numbers");
    }
  }, [nfccClient]);

  const listAgencyVideo = useCallback(async (): Promise<AgencyVideos> => {
    const av = await nfccClient?.agencyvideo.list();
    if (av) {
      return av;
    } else {
      throw new Error("No agency video");
    }
  }, [nfccClient]);

  const listVideoCampaigns = useCallback(async (): Promise<VideoCampaigns> => {
    const av = await nfccClient?.videocampaign.list();
    if (av) {
      return av;
    } else {
      throw new Error("No video campaigns");
    }
  }, [nfccClient]);

  const listChatbots = useCallback(async (): Promise<Chatbots> => {
    const av = await nfccClient?.chatbot.list();
    if (av) {
      return av;
    } else {
      throw new Error("No chatbots");
    }
  }, [nfccClient]);

  const deleteAgency = async (agencyId: number): Promise<void> => {
    await nfccClient?.agency.deleteAgency(agencyId);
  };

  const deleteHoliday = async (
    agencyId: number,
    agencyHolidayConfigId: number,
  ): Promise<void> => {
    await nfccClient?.holidays.deleteHoliday(agencyId, agencyHolidayConfigId);
  };

  const deleteAgencyServiceConfig = async (
    agencyServiceConfigId: number,
  ): Promise<void> => {
    await nfccClient?.agencysrvcconfig.deleteAgencyServiceConfig(
      agencyServiceConfigId,
    );
  };

  const deleteService = async (serviceId: number): Promise<void> => {
    await nfccClient?.srvc.deleteService(serviceId);
  };

  const deletePhoneNumber = async (phoneNumberId: number): Promise<void> => {
    await nfccClient?.phonenumber.deletePhoneNumber(phoneNumberId);
  };

  const deleteAgencyVideo = async (agencyId: number): Promise<void> => {
    await nfccClient?.agencyvideo.deleteAgencyVideo(agencyId);
  };

  const deleteVideoCampaign = async (
    videoCampaignId: number,
  ): Promise<void> => {
    await nfccClient?.videocampaign.deleteVideoCampaign(videoCampaignId);
  };

  const deleteChatbot = async (chatbotId: number): Promise<void> => {
    await nfccClient?.chatbot.deleteChatbot(chatbotId);
  };

  const getAgency = useCallback(
    async (agencyId: number): Promise<Agency> => {
      const agency = await nfccClient?.agency.fetch(agencyId);
      if (agency) {
        return agency;
      } else {
        throw new Error("Cannot get agency");
      }
    },
    [nfccClient],
  );

  const getAgencyVideo = useCallback(
    async (agencyId: number): Promise<AgencyVideo> => {
      const agencyvideo = await nfccClient?.agencyvideo.fetch(agencyId);
      if (agencyvideo) {
        return agencyvideo;
      } else {
        throw new Error("Cannot get agencyvideo");
      }
    },
    [nfccClient],
  );

  const getVideoCampaign = useCallback(
    async (videoCampaignId: number): Promise<VideoCampaign> => {
      const v = await nfccClient?.videocampaign.fetch(videoCampaignId);
      if (v) {
        return v;
      } else {
        throw new Error("Cannot get video campaign");
      }
    },
    [nfccClient],
  );

  const getChatbot = useCallback(
    async (chatbotId: number): Promise<Chatbot> => {
      const v = await nfccClient?.chatbot.fetch(chatbotId);
      if (v) {
        return v;
      } else {
        throw new Error("Cannot get chatbot");
      }
    },
    [nfccClient],
  );

  const getAgencyAvailableServices = useCallback(
    async (agencyId: number): Promise<AgencyAvailableServices> => {
      const s = await nfccClient?.agencyavailablesrvc.list(agencyId);
      if (s) {
        return s;
      } else {
        throw new Error("Cannot get services for agency");
      }
    },
    [nfccClient],
  );

  const listAgencyAgencyServiceConfig = useCallback(
    async (agencyId: number): Promise<AgencyServiceConfigs> => {
      const ascs = await nfccClient?.agencyagencysrvcconfig.list(agencyId);
      if (ascs) {
        return ascs;
      } else {
        throw new Error("Cannot get agency service configs");
      }
    },
    [nfccClient],
  );

  const getStateByName = useCallback(
    async (name: string): Promise<State> => {
      const state = await nfccClient?.state.getState(name);
      if (state) {
        return state;
      } else {
        throw new Error("Cannot get state");
      }
    },
    [nfccClient],
  );

  const listStates = useCallback(async (): Promise<State> => {
    const state = await nfccClient?.state.getState();
    if (state) {
      return state;
    } else {
      throw new Error("Cannot get state");
    }
  }, [nfccClient]);

  const getTimezoneByName = useCallback(
    async (name: string): Promise<Timezone> => {
      const timezone = await nfccClient?.timezone.getTz(name);
      if (timezone) {
        return timezone;
      } else {
        throw new Error("Cannot get timezone");
      }
    },
    [nfccClient],
  );

  const listTimezones = useCallback(async (): Promise<Timezone> => {
    const timezone = await nfccClient?.timezone.getTz();
    if (timezone) {
      return timezone;
    } else {
      throw new Error("Cannot get timezone");
    }
  }, [nfccClient]);

  const getBilling = useCallback(
    async (
      agencyId: number,
      startDate: string,
      endDate: string,
    ): Promise<Billing> => {
      const billing = await nfccClient?.billing.list(
        agencyId,
        startDate,
        endDate,
      );
      if (billing) {
        return billing;
      } else {
        throw new Error("Cannot get billing");
      }
    },
    [nfccClient],
  );

  const getBillingBreakdown = useCallback(
    async (
      agencyId: number,
      startDate: string,
      endDate: string,
      service: string,
      callCost: number,
      feeSchedule: string,
    ): Promise<ConnectionInsights> => {
      const i = await nfccClient?.billingdetail.getBillingDetail(
        agencyId,
        startDate,
        endDate,
        service,
        feeSchedule,
        callCost,
      );
      if (i) {
        return i;
      } else {
        throw new Error("Cannot get billing breakdown");
      }
    },
    [nfccClient],
  );

  const getService = useCallback(
    async (serviceId: number): Promise<Service> => {
      const service = await nfccClient?.srvc.fetch(serviceId);
      if (service) {
        return service;
      } else {
        throw new Error("Cannot get service");
      }
    },
    [nfccClient],
  );

  const getPhoneNumber = useCallback(
    async (phoneNumberId: number): Promise<PhoneNumber> => {
      const pn = await nfccClient?.phonenumber.fetch(phoneNumberId);
      if (pn) {
        return pn;
      } else {
        throw new Error("Cannot get phone number");
      }
    },
    [nfccClient],
  );

  const getInsightsForAgency = useCallback(
    async (
      agencyId: number,
      startDate: string,
      endDate: string,
    ): Promise<ConnectionInsights> => {
      const insights = await nfccClient?.connectioninsights.list(
        agencyId,
        startDate,
        endDate,
      );
      if (insights) {
        return insights;
      } else {
        throw new Error("Cannot get insights");
      }
    },
    [nfccClient],
  );

  const getInsightsReportForAgency = useCallback(
    async (
      agencyId: number,
      startDate: string,
      endDate: string,
      service: string | undefined,
      language: string | undefined,
    ): Promise<ConnectionInsightsReport> => {
      const insights = await nfccClient?.connectioninsightsreport.list(
        agencyId,
        startDate,
        endDate,
        service,
        language,
      );
      if (insights) {
        return insights;
      } else {
        throw new Error("Cannot get insights");
      }
    },
    [nfccClient],
  );

  const getServicePhoneNumbers = useCallback(
    async (serviceId: number): Promise<ServicePhoneNumbers> => {
      const srvcphonenumber =
        await nfccClient?.srvcphonenumber.fetch(serviceId);
      if (srvcphonenumber) {
        return srvcphonenumber;
      } else {
        throw new Error("Cannot get phone numbers");
      }
    },
    [nfccClient],
  );

  const getHolidays = useCallback(
    async (agencyId: number): Promise<Holidays> => {
      const hols = await nfccClient?.holidays.fetch(agencyId);
      if (hols) {
        return hols;
      } else {
        throw new Error("Cannot get hols");
      }
    },
    [nfccClient],
  );

  const updateService = useCallback(
    async (serviceId: number, requestBody: Service): Promise<void> => {
      await nfccClient?.srvc.update(serviceId, requestBody);
    },
    [nfccClient],
  );

  const updateAgency = useCallback(
    async (agencyId: number, requestBody: Agency): Promise<void> => {
      await nfccClient?.agency.update(agencyId, requestBody);
    },
    [nfccClient],
  );

  const updateAgencyServiceConfig = useCallback(
    async (
      agencyServiceConfigId: number,
      requestBody: AgencyServiceConfig,
    ): Promise<void> => {
      await nfccClient?.agencysrvcconfig.update(
        agencyServiceConfigId,
        requestBody,
      );
    },
    [nfccClient],
  );

  const updatePhoneNumber = useCallback(
    async (phoneNumberId: number, requestBody: PhoneNumber): Promise<void> => {
      await nfccClient?.phonenumber.update(phoneNumberId, requestBody);
    },
    [nfccClient],
  );

  const updateAgencyVideo = useCallback(
    async (agencyId: number, requestBody: AgencyVideo): Promise<void> => {
      await nfccClient?.agencyvideo.update(agencyId, requestBody);
    },
    [nfccClient],
  );

  const updateVideoCampaign = useCallback(
    async (
      videoCampaignId: number,
      requestBody: VideoCampaign,
    ): Promise<void> => {
      await nfccClient?.videocampaign.update(videoCampaignId, requestBody);
    },
    [nfccClient],
  );

  const updateChatbot = useCallback(
    async (chatbotId: number, requestBody: Chatbot): Promise<void> => {
      await nfccClient?.chatbot.update(chatbotId, requestBody);
    },
    [nfccClient],
  );

  const createService = useCallback(
    async (requestBody: ServiceNew): Promise<void> => {
      await nfccClient?.srvc.create(requestBody);
    },
    [nfccClient],
  );

  const createHoliday = useCallback(
    async (agencyId: number, requestBody: HolidayNew): Promise<void> => {
      await nfccClient?.holidays.create(agencyId, requestBody);
    },
    [nfccClient],
  );

  const provisionPhoneNumber = useCallback(
    async (pnType: string): Promise<PhoneNumber> => {
      const pn =
        await nfccClient?.provisionphonenumber.provisionPhoneNumber(pnType);
      if (pn) {
        return pn;
      } else {
        throw new Error("Cannot provision phone number");
      }
    },
    [nfccClient],
  );

  const createAgencyServiceConfig = useCallback(
    async (requestBody: AgencyServiceConfigNew): Promise<void> => {
      await nfccClient?.agencysrvcconfig.create(requestBody);
    },
    [nfccClient],
  );

  const createAgencyVideo = useCallback(
    async (requestBody: AgencyVideoNew): Promise<void> => {
      await nfccClient?.agencyvideo.create(requestBody);
    },
    [nfccClient],
  );

  const createVideoCampaign = useCallback(
    async (requestBody: VideoCampaignNew): Promise<void> => {
      await nfccClient?.videocampaign.create(requestBody);
    },
    [nfccClient],
  );

  const createChatbot = useCallback(
    async (requestBody: ChatbotNew): Promise<void> => {
      await nfccClient?.chatbot.create(requestBody);
    },
    [nfccClient],
  );

  const getUser = useCallback(
    async (userId: number): Promise<User> => {
      const user = await nfccClient?.user.fetch(userId);
      if (user) {
        return user;
      } else {
        throw new Error("Cannot get user");
      }
    },
    [nfccClient],
  );

  const listUsersEmailFilter = useCallback(
    async (email: string): Promise<Users> => {
      const users = await nfccClient?.user.list(email);
      if (users) {
        return users;
      } else {
        throw new Error("Cannot get user by email");
      }
    },
    [nfccClient],
  );

  const updateUser = useCallback(
    async (userId: number, requestBody: User): Promise<void> => {
      await nfccClient?.user.update(userId, requestBody);
    },
    [nfccClient],
  );

  const updateHoliday = useCallback(
    async (
      agencyId: number,
      agencyHolidayConfigId: number,
      requestBody: Holiday,
    ): Promise<void> => {
      await nfccClient?.holidays.update(
        agencyId,
        agencyHolidayConfigId,
        requestBody,
      );
    },
    [nfccClient],
  );

  const setUserPassword = useCallback(
    async (requestBody: SetPasswordBody): Promise<void> => {
      await nfccClient?.password.setPassword(requestBody);
    },
    [nfccClient],
  );

  const validateUserPassword = useCallback(
    async (requestBody: UserAuth): Promise<ValidationResponse> => {
      const validationResponse =
        await nfccClient?.validation.validatePassword(requestBody);
      if (validationResponse) {
        return validationResponse;
      } else {
        throw new Error("Cannot validate password");
      }
    },
    [nfccClient],
  );

  return (
    <NFCCContext.Provider
      value={{
        providerReady,
        connect,
        listAgenciesNFCCView,
        deleteAgency,
        getAgency,
        listAgencyAudits,
        listAudits,
        listServices,
        deleteService,
        updateService,
        getService,
        listCategories,
        listAgencies,
        getServicePhoneNumbers,
        createService,
        listPhoneNumbers,
        deletePhoneNumber,
        getPhoneNumber,
        updatePhoneNumber,
        getUser,
        updateUser,
        listUsersEmailFilter,
        listTemplates,
        getTimezoneByName,
        getStateByName,
        createAgencyServiceConfig,
        updateAgency,
        listStates,
        listTimezones,
        getInsightsForAgency,
        getInsightsReportForAgency,
        listAgencyAgencyServiceConfig,
        updateAgencyServiceConfig,
        deleteAgencyServiceConfig,
        getHolidays,
        getAgencyAvailableServices,
        setUserPassword,
        validateUserPassword,
        getBilling,
        getBillingBreakdown,
        createHoliday,
        updateHoliday,
        deleteHoliday,
        provisionPhoneNumber,
        getAgencyVideo,
        listAgencyVideo,
        deleteAgencyVideo,
        updateAgencyVideo,
        createAgencyVideo,
        listVideoCampaigns,
        deleteVideoCampaign,
        getVideoCampaign,
        updateVideoCampaign,
        createVideoCampaign,
        listChatbots,
        deleteChatbot,
        getChatbot,
        updateChatbot,
        createChatbot,
      }}
    >
      {children}
    </NFCCContext.Provider>
  );
};
