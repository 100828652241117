/* eslint @typescript-eslint/no-var-requires: "off" */
import { StepperStage1 } from "./StepperStage1";
import { Box } from "@twilio-paste/core/box";
import { CheckboxGroup, Checkbox } from "@twilio-paste/core/checkbox";
import { Flex } from "@twilio-paste/core/flex";
import { Button } from "@twilio-paste/core/button";
import {
  generatePath,
  Link,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useEffect, useState, FC } from "react";
import { Services } from "@ciptex/nfcc";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { useToasterContext } from "../../hooks/useToasterContext";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { SkeletonLoader } from "@twilio-paste/core/skeleton-loader";
import { Stack } from "@twilio-paste/core/stack";
import { Paragraph } from "@twilio-paste/core/paragraph";

type LocationProps = {
  state: {
    selectedServiceIds: number[];
    channelConfigs: any[];
    channelConfigInstances: any[];
  };
};

export const ServiceSelection: FC = () => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState<boolean>();
  const [services, setServices] = useState<Services>();
  const [categories, setCategories] = useState<string[]>();
  const [selectedServiceIds, setSelectedServiceIds] = useState<number[]>();
  const [channelConfigs, setChannelConfigs] = useState<any[]>();

  const [channelConfigInstances, setChannelConfigInstances] = useState<any[]>();

  const location = useLocation() as unknown as LocationProps;

  const { appState } = useAppState();
  const { agencyId } = useParams();

  const { getAgencyAvailableServices, listAgencyAgencyServiceConfig } =
    useNFCCContext();

  const { toaster } = useToasterContext();

  const _ = require("lodash");

  useEffect(() => {
    (async () => {
      try {
        const data: any[] = await getAgencyAvailableServices(
          parseInt(agencyId ?? "0") || appState.agencyId,
        ); // need to list services that are available to this agency only

        // remove any services where there is at least one service config
        const ascs = await listAgencyAgencyServiceConfig(
          parseInt(agencyId ?? "0") || appState.agencyId,
        );

        if (!(ascs as any).error_message) {
          let toRemove = [];

          for (const a of ascs) {
            if (a.service) {
              toRemove.push(a.service.serviceId);
            }
          }

          toRemove = [...new Set(toRemove)];

          const servs = [];
          for (const d of data) {
            if (!toRemove.includes(d.serviceId)) {
              servs.push(d);
            }
          }
          setServices(servs);

          setCategories([...new Set(servs.map((item) => item.category))]);
        } else {
          setServices(data);
          setCategories([...new Set(data.map((item) => item.category))]);
        }

        const { state } = location;
        if (state) {
          setSelectedServiceIds(state.selectedServiceIds);
          setChannelConfigs(state.channelConfigs);
          if (state.channelConfigInstances) {
            setChannelConfigInstances(state.channelConfigInstances);
          }
        }

        setLoaded(true);
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Could not retrieve service data",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    })();
  }, []);

  const onCheckboxChange = ({ target }: any) => {
    if (target.checked) {
      if (services) {
        const newSelectedServices: number[] = (selectedServiceIds ?? []).map(
          (x) => x,
        );
        newSelectedServices.push(parseInt(target.value));
        setSelectedServiceIds(newSelectedServices);
      }
    } else {
      if (services) {
        const newSelectedServices: number[] = (selectedServiceIds ?? []).filter(
          (i) => i !== parseInt(target.value),
        );
        setSelectedServiceIds(newSelectedServices);
      }
    }
  };

  const setAllChecked = (checked: boolean) => {
    if (checked) {
      if (services) {
        const s: number[] = services.map(({ serviceId }) => serviceId ?? -1);
        setSelectedServiceIds(s);
      }
    } else {
      if (services) {
        setSelectedServiceIds([]);
      }
    }
  };

  function allChecked(): boolean {
    try {
      if (services) {
        return _.isEmpty(
          _.xor(
            (services ?? []).map(({ serviceId }) => serviceId),
            selectedServiceIds,
          ),
        );
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  const getNextLink = () => {
    if (appState.role === "nfcc_admin") {
      const p = generatePath("/agencies/:agencyId/start/selection/channel", {
        agencyId: agencyId,
      });
      return p;
    } else {
      const p = generatePath("/start/selection/channel");
      return p;
    }
  };

  const getPreviousLink = () => {
    if (appState.role === "nfcc_admin") {
      const p = generatePath("/agencies/:agencyId/start/", {
        agencyId: agencyId,
      });
      return p;
    } else {
      const p = generatePath("/start/");
      return p;
    }
  };

  return (
    <>
      <StepperStage1 />

      <Flex marginY="space60">
        <Box marginY="space60">
          <HeaderTitleText titleText="Service Selection" />
          <Paragraph>Please select the services you want to offer</Paragraph>
        </Box>
      </Flex>

      <Flex marginY="space60">
        <Box
          display="flex"
          columnGap="space200"
          flexDirection="row"
          flexWrap="wrap"
        >
          {loaded ? (
            categories &&
            categories?.map((c: any, index: number) => (
              <CheckboxGroup
                key={"parentgroup-" + c}
                name="servicescbgroup"
                legend=""
              >
                <CheckboxGroup
                  name={"services" + index}
                  legend=""
                  helpText=""
                  key={"services" + index}
                >
                  {index === 0 && (
                    <Checkbox
                      key="select_all"
                      id="select_all"
                      checked={allChecked()}
                      indeterminate={
                        !allChecked() && (selectedServiceIds ?? []).length > 1
                      }
                      onChange={(e) => setAllChecked(e.target.checked)}
                    >
                      Select all
                    </Checkbox>
                  )}
                  {c}
                  {services &&
                    (services ?? [])
                      .filter((i) => i.category === c)
                      .map((s: any, index: number) => (
                        <Checkbox
                          key={s.serviceId}
                          id={s.serviceId + ""}
                          checked={(selectedServiceIds ?? []).includes(
                            s.serviceId,
                          )}
                          value={s.serviceId}
                          name={s.name}
                          onClick={(e) => onCheckboxChange(e)}
                        >
                          {s.name}
                        </Checkbox>
                      ))}
                </CheckboxGroup>
              </CheckboxGroup>
            ))
          ) : (
            <Box width="100%">
              <Stack orientation="vertical" spacing="space60">
                <SkeletonLoader width="80vw" />
                <SkeletonLoader width="80vw" />
                <SkeletonLoader width="80vw" />
              </Stack>
            </Box>
          )}
        </Box>
      </Flex>

      <Box
        display="flex"
        justifyContent="space-between"
        position="relative"
        bottom="0"
        marginTop="space60"
      >
        <Button variant="secondary">
          <Link
            style={{ color: "rgb(18, 28, 45)", textDecoration: "none" }}
            to={getPreviousLink()}
          >
            Back
          </Link>
        </Button>

        {(selectedServiceIds ?? []).length === 0 && (
          <Button variant="primary" disabled>
            Continue
          </Button>
        )}
        {(selectedServiceIds ?? []).length > 0 && (
          <Button variant="primary">
            <Link
              style={{ color: "white", textDecoration: "none" }}
              to={getNextLink()}
              state={{
                selectedServiceIds: selectedServiceIds,
                channelConfigs: channelConfigs,
                channelConfigInstances: channelConfigInstances,
              }}
            >
              Continue
            </Link>
          </Button>
        )}
      </Box>
    </>
  );
};
