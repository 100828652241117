import { FC, useState, FormEvent, useEffect, ChangeEvent } from "react";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { Box } from "@twilio-paste/core/box";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { HelpText } from "@twilio-paste/core/help-text";
import { Heading } from "@twilio-paste/core/heading";
import { Button } from "@twilio-paste/core/button";
import { Alert } from "@twilio-paste/core/alert";
import { Text } from "@twilio-paste/core/text";
import { UserAuth } from "@ciptex/nfcc";
import { useHeight } from "../../hooks/useHeight/useHeight";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LOGO, Role } from "../../constants";
import { Anchor, Stack } from "@twilio-paste/core";
import { ShowIcon } from "@twilio-paste/icons/esm/ShowIcon";
import { HideIcon } from "@twilio-paste/icons/esm/HideIcon";

export const LoginPage: FC = () => {
  const height = useHeight();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { signIn, token, appDispatch, appState } = useAppState();
  const { connect: ccpConnect, providerReady } = useNFCCContext();
  const [form, setForm] = useState<UserAuth>({
    username: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<boolean>(false);
  const [passwordShown, setPasswordShown] = useState(false);

  useEffect(() => {
    if (
      providerReady &&
      appState.token &&
      appState.token !== "" &&
      appState.token !== null
    ) {
      if (appState.role === Role.admin) {
        navigate(searchParams.get("redirect") || "/agencies", {
          replace: true,
        });
      } else if (appState.role === Role.client) {
        navigate(searchParams.get("redirect") || "/home", { replace: true });
      }
    }
  }, [providerReady, appState.token]);

  useEffect(() => {
    if (token) {
      appDispatch({ type: "set-token", token: token.token ? token.token : "" });
      appDispatch({ type: "set-role", role: token.role ? token.role : "" });
      appDispatch({ type: "set-email", email: token.email ? token.email : "" });
      appDispatch({ type: "set-agencyId", agencyId: token.agencyId ?? 0 });
      appDispatch({
        type: "set-agencyName",
        agencyName: token.agencyName ?? "",
      });
      ccpConnect(token.token ? token.token : "");
    }
  }, [token]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setAlert(false);
    setIsLoading(true);

    try {
      await signIn(
        form.username ? form.username : "",
        form.password ? form.password : "",
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAlert(true);
    }
  };

  const onChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [name]: value });
  };

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  return (
    <Box
      height={height}
      display="flex"
      flexDirection="column"
      flex={1}
      alignContent="center"
      justifyContent="center"
      alignItems="center"
      justifyItems="center"
    >
      <Box
        borderColor="colorBorder"
        borderWidth="borderWidth10"
        borderStyle="solid"
        width={["100%", "30rem", "30rem"]}
        borderRadius="borderRadius30"
      >
        <form onSubmit={onSubmit}>
          <Box
            display="flex"
            width="fit-content"
            alignItems="center"
            style={{ background: "#067fa7" }}
            justifyContent="center"
            paddingTop="space60"
            paddingBottom="space60"
          >
            <img width="50%" src={LOGO}></img>
          </Box>
          <Box padding="space100">
            {alert && (
              <Box marginY="space30" paddingBottom="space60">
                <Alert variant="error">
                  <Text as="span">
                    <strong>Wrong username or password.</strong> Forgotten your
                    password? Use the &#39;forgot password&#39; option at the
                    bottom of this form
                  </Text>
                </Alert>
              </Box>
            )}
            <Heading as="h2" variant="heading10">
              Login
            </Heading>
            <Box marginY="space60">
              <Label htmlFor="username" required>
                Email
              </Label>
              <Input
                aria-describedby="username_help_text"
                id="username"
                name="username"
                autoComplete="username"
                onChange={onChange}
                value={form.username}
                type="text"
                placeholder="username"
                required
              />
              <HelpText id="username_help_text">
                Please enter a valid username.
              </HelpText>
            </Box>
            <Box marginY="space60">
              <Label htmlFor="password" required>
                Password
              </Label>
              <Box display="flex" flexDirection="row">
                <Input
                  aria-describedby="password_help_text"
                  id="password"
                  name="password"
                  onChange={onChange}
                  value={form.password}
                  autoComplete="current-password"
                  type={passwordShown ? "text" : "password"}
                  required
                />
                <Box marginLeft="spaceNegative100">
                  <Button
                    variant="secondary_icon"
                    size="icon"
                    onClick={togglePassword}
                  >
                    {passwordShown ? (
                      <HideIcon decorative={false} title="Hide password" />
                    ) : (
                      <ShowIcon decorative={false} title="Show password" />
                    )}
                  </Button>
                </Box>
              </Box>
              <HelpText id="password_help_text">
                Please enter a valid password.
              </HelpText>
            </Box>

            <Box>
              <Box
                marginY="space80"
                float="right"
                justifyContent="center"
                alignItems="self-end"
              >
                <Button variant="primary" type="submit" loading={isLoading}>
                  Login
                </Button>
              </Box>
              <Box marginY="space60" float="left">
                <Stack orientation="vertical" spacing="space30">
                  {/* <a href= //'https://dev-aek81y5x1hmxxjuy.us.auth0.com/samlp/uj56nCeRnGNMjUOK2nLR1MpV1wnoozHR'//
								'https://contactcenter.okta.com/app/contactcenter_caztestsaml_1/exkavvhrpjYxzIJvc4x7/sso/saml'
							>
                Log in through SSO
							</a> */}

                  <Anchor href="reset">Forgot password?</Anchor>
                </Stack>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
