import { FC, useState } from "react";
import { AlertDialog } from "@twilio-paste/alert-dialog";
import { Button } from "@twilio-paste/core/button";
import { Box } from "@twilio-paste/core/box";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useNavigate } from "react-router-dom";

export const LogoutModal: FC = () => {
  const { signOut, appDispatch } = useAppState();
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const navigate = useNavigate();
  const handleConfirm = () => {
    signOut();
    appDispatch({ type: "reset-state" });
    setIsOpen(false);
    navigate("/logout");
  };

  return (
    <Box>
      <Button variant="destructive" onClick={handleOpen}>
        Logout
      </Button>
      <AlertDialog
        heading="Logout"
        isOpen={isOpen}
        destructive
        onConfirm={handleConfirm}
        onConfirmLabel="Yes"
        onDismiss={handleClose}
        onDismissLabel="No"
      >
        Are you sure you want to logout?
      </AlertDialog>
    </Box>
  );
};
