/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";

import { Box } from "@twilio-paste/core/box";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { Button } from "@twilio-paste/core/button";
import { DownloadIcon } from "@twilio-paste/icons/esm/DownloadIcon";
import { Select, Option } from "@twilio-paste/core/select";
import { Label } from "@twilio-paste/core/label";
import { Table, THead, TBody, Th, Tr, Td } from "@twilio-paste/core/table";
import { useToasterContext } from "../../hooks/useToasterContext";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { useAppState } from "../../hooks/useAppState/useAppState";
import {
  Agencies,
  Billing,
  ConnectionInsights,
  User,
  Users,
} from "@ciptex/nfcc";
import { ShowIcon } from "@twilio-paste/icons/esm/ShowIcon";
import { FilterIcon } from "@twilio-paste/icons/esm/FilterIcon";
import { Separator } from "@twilio-paste/core/separator";
import { DatePicker, formatReturnDate } from "@twilio-paste/core/date-picker";
import { SubtitleText } from "../HeaderTitleText/SubtitleText";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { Scroller } from "../Scroller/Scroller";
import { HelpText } from "@twilio-paste/core";
import { timezones } from "../../constants";

export const BillingPage: FC = () => {
  const [loaded, setLoaded] = useState<boolean>();
  const [serviceFilterValue, setServiceFilterValue] =
    useState<string>("All Services");
  const [servicesFilter, setServicesFilter] = useState<string[]>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [billing, setBilling] = useState<Billing>();
  const [billingBreakdown, setBillingBreakdown] =
    useState<ConnectionInsights>();
  const [costTypes, setCostTypes] = useState<string[]>();
  const [subtitle, setSubtitle] = useState<string>("");
  const [tz, setTz] = useState<string>();
  const [preDateError, setPreDateError] = useState<string>("");
  const [dateError, setDateError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const { agencyId } = useParams();
  const {
    getBilling,
    getAgencyAvailableServices,
    getBillingBreakdown,
    listUsersEmailFilter,
    getUser,
  } = useNFCCContext();
  const { appState } = useAppState();

  const { toaster } = useToasterContext();

  const tzs = timezones

  const handleFilterChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (billing) {
      if (target.name === "service") {
        setServiceFilterValue(target.value);
      }
      // If filter is changed the apply button loading state is set to false so
      // user can click apply again
      setLoading(false);
    }
  };

  const handleChange = (name: string, val: string, format: string) => {
    const d = formatReturnDate(val, format);

    if (name === "startDate") {
      setStartDate(d);
    } else {
      setEndDate(d);
    }
    setLoading(false);
  };

  const applyFilters = async (
    forcedStartDate: string,
    forcedEndDate: string,
  ) => {
    if (!forcedStartDate) {
      forcedStartDate = startDate ?? "";
    }
    if (!forcedEndDate) {
      forcedEndDate = endDate ?? "";
    }

    if (forcedStartDate && forcedEndDate) {
      if (forcedEndDate < forcedStartDate) {
        setDateError("End date cannot be before start date");
        setPreDateError("Please fix error");
        return;
      } else {
        // clear billing breakdown out on clicking apply button
        setBillingBreakdown(null!);
        setLoaded(false);
        setLoading(true);
        setDateError("");
        setPreDateError("");

        let b = await getBilling(
          parseInt(agencyId ?? "0") || appState.agencyId,
          forcedStartDate ?? "",
          forcedEndDate ?? "",
        );
        if (serviceFilterValue !== "All Services") {
          b = b.filter((s) => s.service === serviceFilterValue);
        }
        setBilling(b);

        if ((b as any).error_message) {
          setCostTypes([]);
          setLoaded(false);
          setLoading(false);
          toaster.push({
            message: "Currently there is no billing data in this filter range",
            variant: "neutral",
            dismissAfter: 4000,
          });
        } else {
          setCostTypes([
            ...new Set(b.map(({ feeSchedule }) => feeSchedule ?? "")),
          ]);
          setLoaded(true);
          setLoading(false);
        }

        // Toaster push so if on applying filter there is no data, it will show a toaster message
        if (b.length === 0) {
          setLoading(false);
          toaster.push({
            message: "Currently there is no billing data in this filter range",
            variant: "neutral",
            dismissAfter: 4000,
          });
        }

        const s = await getAgencyAvailableServices(
          parseInt(agencyId ?? "0") || appState.agencyId,
        );
        setServicesFilter([...new Set(s.map(({ name }) => name ?? ""))]);
      }
    }
  };

  const handleBreakdown = async (
    feeSchedule: string,
    service: string,
    callCost: number,
  ) => {
    const b = await getBillingBreakdown(
      parseInt(agencyId ?? "0") || appState.agencyId,
      startDate ?? "",
      endDate ?? "",
      service,
      callCost,
      feeSchedule,
    );
    if ((b as any).error_message) {
      console.error("no data");
      setLoading(false);
    } else {
      for (const a of b) {
        const dt = a.endDateTime?.replace(" ", "T").split(".")[0] + "+00:00";
        const dtLocal = DateTime.fromISO(dt, {
          zone: tz,
        });
        a.endDateTime = dtLocal.toFormat("dd MMM yyyy HH:mm");
      }
      setBillingBreakdown(b);
      setSubtitle(feeSchedule + " - " + service);
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const users: Users = await listUsersEmailFilter(
          encodeURI(appState.email),
        );
        const u: User = await getUser(users[0].userId ?? 0);
        const tz1 = (u.timezone as any)?.timezone ?? "EST";
        const tz = tzs.find((t) => t.name === tz1);
        setTz(tz?.tag);

        const d = new Date();
        const endDate = d.toISOString().substring(0, 10);
        setEndDate(endDate);

        d.setDate(d.getDate() - 30);
        const startDate = d.toISOString().substring(0, 10);
        setStartDate(startDate);

        const s: Agencies = await getAgencyAvailableServices(
          parseInt(agencyId ?? "0") || appState.agencyId,
        );
        setServicesFilter([...new Set(s.map(({ name }) => name ?? ""))]);

        const b: Billing = await getBilling(
          parseInt(agencyId ?? "0") || appState.agencyId,
          startDate,
          endDate,
        );
        setBilling(b);
        setLoading(true);

        if ((b as any).error_message) {
          setLoading(false);
          console.error("error");
          toaster.push({
            message: (b as any).error_message,
            variant: "error",
            dismissAfter: 4000,
          });
        } else {
          setCostTypes([
            ...new Set(b.map(({ feeSchedule }) => feeSchedule ?? "")),
          ]);
          setLoaded(true);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
        toaster.push({
          message: "Could not retrieve the billing data",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    })();
  }, []);

  const getDate = () => {
    const today = DateTime.local();
    const todayDate = today.toFormat("dd-MM-yyyy-HH-mm-ss");
    return todayDate;
  };

  const exportTableData = () => {
    if (billing) {
      const csvData = [
        [
          "Cost Type",
          "Service",
          "Number Of Calls",
          "Cost Per Call",
          "Total Cost Per Line",
        ],
        ...billing.map(
          ({ feeSchedule, service, count, unitCost, totalCost }) => [
            feeSchedule?.replace(",", ";  "),
            service,
            count,
            "$" + unitCost,
            "$" + totalCost,
          ],
        ),
      ];
      const csv = csvData.map((row) => row.join(",")).join("\n");
      const csvBlob = new Blob([csv], { type: "text/csv" });
      const csvUrl = URL.createObjectURL(csvBlob);
      const downloadLink = document.createElement("a");
      downloadLink.href = csvUrl;
      downloadLink.download = `billing-${getDate()}.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  // clear filters function applied on the clear button
  const clearFilters = async () => {
    setLoaded(false);
    // Set the service filter value to the first service in the list
    setServiceFilterValue("All Services");
    // setStartDate to todays date minus 7 days
    const d = new Date();
    d.setDate(d.getDate() - 30);
    const startDate = d.toISOString().substring(0, 10);
    setStartDate(startDate);
    // setEndDate to todays date
    const f = new Date();
    f.setDate(f.getDate());
    const today = f.toISOString().substring(0, 10);
    setBillingBreakdown(null!);
    setEndDate(today);
    applyFilters(startDate, today);
  };

  return (
    <>
      <Box width="100%">
        <Scroller />
        <HeaderTitleText titleText="Billing" />

        {/* Export CSV piece */}
        <Box display="flex" justifyContent="flex-end">
          <Button variant="secondary" onClick={exportTableData}>
            Download CSV
            <DownloadIcon decorative={false} title="download csv icon" />
          </Button>
        </Box>

        {/* Filters piece */}
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          columnGap="space100"
          alignItems="flex-end"
          marginY="space60"
        >
          <Box
            display="flex"
            flexDirection="row"
            columnGap="space60"
            alignItems="flex-end"
          >
            <Box display="flex" flexDirection="column" columnGap="space100">
              <Label htmlFor="service">Service</Label>
              <Select
                id="service"
                name="service"
                value={serviceFilterValue}
                onChange={handleFilterChange}
              >
                <Option value="All Services" key="allServices">
                  All Services
                </Option>
                {servicesFilter &&
                  servicesFilter?.map((f: any, index: number) => (
                    <Option value={f} key={"serv-" + index}>
                      {f}
                    </Option>
                  ))}
              </Select>
            </Box>

            <Box display="flex" flexDirection="column" columnGap="space100">
              {preDateError.length > 0 && (
                <HelpText variant="error">{preDateError}</HelpText>
              )}
              <Label htmlFor="startDate">Start Date</Label>
              <DatePicker
                id="startDate"
                name="startDate"
                value={startDate}
                onChange={(evt) =>
                  handleChange(evt.target.name, evt.target.value, "yyyy-MM-dd")
                }
                enterKeyHint={null!}
              />
            </Box>

            <Box display="flex" flexDirection="column" columnGap="space100">
              {dateError.length > 0 && (
                <HelpText variant="error">{dateError}</HelpText>
              )}
              <Label htmlFor="endDate">End Date</Label>
              <DatePicker
                id="endDate"
                name="endDate"
                value={endDate}
                onChange={(evt) =>
                  handleChange(evt.target.name, evt.target.value, "yyyy-MM-dd")
                }
                enterKeyHint={null!}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            columnGap="space100"
            justifyItems="flex-end"
          >
            <Box
              display="flex"
              justifyContent="flex-end"
              height="fit-content"
              columnGap="space60"
            >
              <Button variant="link" onClick={clearFilters}>
                Clear
              </Button>

              {loading ? (
                <Button variant="primary" loading>
                  <FilterIcon decorative={false} title="Apply filters" />
                  Apply
                </Button>
              ) : (
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                <Button
                  variant="primary"
                  onClick={(e: any) => {
                    applyFilters(null!, null!);
                  }}
                >
                  <FilterIcon decorative={false} title="Apply filters" />
                  Apply
                </Button>
              )}
            </Box>
          </Box>
        </Box>

        {loaded && <Box display="flex" justifyContent="flex-end"></Box>}

        {/* Tables piece */}
        {/* Calls table piece */}
        <Box width="100%">
          <Box>
            <Table>
              <THead>
                <Tr>
                  <Th>Cost Type</Th>
                  <Th>Service</Th>
                  <Th>Number Of Calls</Th>
                  <Th>Cost Per Call</Th>
                  <Th>Total Cost Per Line</Th>
                  <Th />
                </Tr>
              </THead>
              <TBody>
                {billing && billing.length > 0 && costTypes && loaded ? (
                  costTypes &&
                  costTypes.map((ct: any, rowI: number) => (
                    <>
                      <Tr key={rowI}>
                        <Td
                          rowSpan={
                            billing.filter((x) => x.feeSchedule === ct).length
                          }
                        >
                          {ct}
                        </Td>
                        <Td>
                          {
                            billing.filter((x) => x.feeSchedule === ct)[0]
                              .service
                          }
                        </Td>
                        <Td>
                          {billing.filter((x) => x.feeSchedule === ct)[0].count}
                        </Td>
                        <Td>
                          {"$" +
                            billing.filter((x) => x.feeSchedule === ct)[0]
                              .unitCost}
                        </Td>
                        <Td>
                          {"$" +
                            billing.filter((x) => x.feeSchedule === ct)[0]
                              .totalCost}
                        </Td>
                        <Td>
                          <Button
                            variant="secondary"
                            onClick={(e) =>
                              handleBreakdown(
                                billing.filter((x) => x.feeSchedule === ct)[0]
                                  .feeSchedule ?? "",
                                billing.filter((x) => x.feeSchedule === ct)[0]
                                  .service ?? "",
                                billing.filter((x) => x.feeSchedule === ct)[0]
                                  .unitCost ?? 0,
                              )
                            }
                          >
                            <ShowIcon
                              decorative={false}
                              title="View Breakdown"
                            />
                          </Button>
                        </Td>
                      </Tr>
                      {billing
                        .filter((x) => x.feeSchedule === ct)
                        .slice(1)
                        .map((b: any, rowIndex: number) => (
                          <Tr key={rowIndex + "-b"}>
                            <Td>{b.service}</Td>
                            <Td>{b.count}</Td>
                            <Td>{"$" + b.unitCost}</Td>
                            <Td>{"$" + b.totalCost}</Td>
                            <Td>
                              <Button
                                variant="secondary"
                                onClick={(e) =>
                                  handleBreakdown(
                                    b.feeSchedule,
                                    b.service,
                                    b.unitCost,
                                  )
                                }
                              >
                                <ShowIcon
                                  decorative={false}
                                  title="View Breakdown"
                                />
                              </Button>
                            </Td>
                          </Tr>
                        ))}
                    </>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={7}>No data to display</Td>
                  </Tr>
                )}
              </TBody>
            </Table>
          </Box>
        </Box>

        {billingBreakdown && loaded && (
          <Box paddingY="space60">
            <Separator orientation="horizontal" />
          </Box>
        )}

        <Box display="flex" flexDirection="column">
          {billingBreakdown && loaded && (
            <Box marginY="space60">
              <SubtitleText subtitleText={subtitle} />
              <Table aria-label="Connection data table" striped>
                <THead>
                  <Tr>
                    <Th>Connection SID</Th>
                    <Th>Language</Th>
                    <Th>Connection Type</Th>
                    <Th>Service</Th>
                    <Th>State</Th>
                    <Th>ZIP Code</Th>
                    <Th>Client Phone Number</Th>
                    <Th>Dialed Phone Number</Th>
                    <Th>Destination</Th>
                    <Th>Email</Th>
                    <Th>Is Duplicate</Th>
                    <Th>Timestamp</Th>
                  </Tr>
                </THead>
                <TBody>
                  {billingBreakdown && loaded ? (
                    billingBreakdown &&
                    billingBreakdown.map((f: any, rowIndex: number) => (
                      <Tr key={rowIndex}>
                        <Td>{f.flowSid}</Td>
                        <Td>{f.language}</Td>
                        <Td>{f.connectionType}</Td>
                        <Td>{f.service}</Td>
                        <Td>{f.state}</Td>
                        <Td>{f.zipCode}</Td>
                        <Td>{f.fromPhone}</Td>
                        <Td>{f.toPhone}</Td>
                        <Td>{f.destinationPhone}</Td>
                        <Td>{f.fromEmail}</Td>
                        <Td>
                          {(f.duplicateCheckResult === 0 && "No") || "Yes"}
                        </Td>
                        <Td>{f.endDateTime}</Td>
                      </Tr>
                    ))
                  ) : (
                    <TableSkeletonLoader numberOfTd={11} numberOfTr={1} />
                  )}
                </TBody>
              </Table>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
