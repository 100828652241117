import { FC, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Loading } from "../components/Loading";
import { MainContainer } from "../components/MainContainer/MainContainer";
import { NFCCProvider } from "../components/NFCCProvider/NFCCProvider";
import { ToasterProvider } from "../components/ToasterProvider/ToasterProvider";
import { LoginPage } from "../components/LoginPage/LoginPage";
import { AgenciesTable } from "../components/Agencies/AgenciesTable";
import { AgencyHome } from "../components/Agencies/AgencyHome";
import { AuditsTable } from "../components/Audits/AuditsTable";
import { useAppState } from "../hooks/useAppState/useAppState";
import { WizardStart } from "../components/Wizard/WizardStart";
import { ServiceSelection } from "../components/Wizard/ServiceSelection";
import { ChannelSelection } from "../components/Wizard/ChannelSelection";
import { ServicesTable } from "../components/Services/ServicesTable";
import { SetupPage } from "../components/Wizard/SetupPage";
import { EditService } from "../components/Services/EditService";
import { AddService } from "../components/Services/AddService";
import { PhoneNumbersTable } from "../components/PhoneNumbers/PhoneNumbersTable";
import { EditPhoneNumber } from "../components/PhoneNumbers/EditPhoneNumber";
import { ReviewSetup } from "../components/Wizard/ReviewSetup";
import { Complete } from "../components/Wizard/Complete";
import { BillingPage } from "../components/Billing/BillingPage";
import { UserProfile } from "../components/UserProfile/UserProfile";
import { ChangeHistoryTable } from "../components/ChangeHistory/ChangeHistoryTable";
import { ConnectionInsightsPage } from "../components/ConnectionInsights/ConnectionInsightsPage";
import { HolidaysPage } from "../components/Holidays/HolidaysPage";
import { ManageServicesPage } from "../components/ManageServices/ManageServicesPage";
import { PasswordReset } from "../components/PasswordReset/PasswordReset";
import { EmailReset } from "../components/PasswordReset/EmailReset";
import { LogoutPage } from "../components/LogoutPage/LogoutPage";
import { ManageServicesRedirect } from "../components/ManageServices/ManageServicesRedirect";
import { AgencyVideoTable } from "../components/AgencyVideo/AgencyVideoTable";
import { EditAgencyVideo } from "../components/AgencyVideo/EditAgencyVideo";
import { VideoCampaignsTable } from "../components/VideoCampaigns/VideoCampaignsTable";
import { EditVideoCampaign } from "../components/VideoCampaigns/EditVideoCampaign";
import { ChatbotTable } from "../components/Chatbots/ChatbotTable";
import { EditChatbot } from "../components/Chatbots/EditChatbot";

export const Router: FC = () => {
  const { appState } = useAppState();
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter basename="/">
        <NFCCProvider>
          <ToasterProvider>
            <Routes>
              <Route index element={<LoginPage />} />
              <Route path="login" element={<LoginPage />} />
              <Route path="logout" element={<LogoutPage />} />
              <Route path="reset" element={<EmailReset />} />
              <Route path="resetpassword" element={<PasswordReset />} />

              <Route path="/" element={<MainContainer />}>
                {/* {appState.role === Role.admin && <>
								<Route path="profile/:userId" element={<UserProfile />} /> </>} */}
                <Route path="agencies" element={<AgenciesTable />} />
                <Route path="audits" element={<AuditsTable />} />
                <Route path="services" element={<ServicesTable />} />
                <Route path="services/add" element={<AddService />} />
                <Route
                  path="services/edit/:serviceId"
                  element={<EditService />}
                />
                <Route path="services/edit" element={<ServicesTable />} />
                <Route
                  path="phone-numbers/edit/:phoneNumberId"
                  element={<EditPhoneNumber />}
                />
                <Route
                  path="phone-numbers/edit"
                  element={<PhoneNumbersTable />}
                />
                <Route path="phone-numbers" element={<PhoneNumbersTable />} />
                <Route path="/profile" element={<UserProfile />} />

                <Route
                  path="agency-video/edit/:agencyId2"
                  element={<EditAgencyVideo />}
                />
                <Route path="agency-video/add" element={<EditAgencyVideo />} />
                <Route
                  path="agency-video/edit"
                  element={<AgencyVideoTable />}
                />
                <Route path="agency-video" element={<AgencyVideoTable />} />

                <Route path="booths" element={<VideoCampaignsTable />} />
                <Route
                  path="video-campaigns"
                  element={<VideoCampaignsTable />}
                />
                <Route
                  path="booths/edit/:videoCampaignId"
                  element={<EditVideoCampaign />}
                />
                <Route
                  path="video-campaigns/edit/:videoCampaignId"
                  element={<EditVideoCampaign />}
                />
                <Route path="booths/edit" element={<VideoCampaignsTable />} />
                <Route
                  path="video-campaigns/edit"
                  element={<VideoCampaignsTable />}
                />
                <Route path="booths/add" element={<EditVideoCampaign />} />
                <Route
                  path="video-campaigns/add"
                  element={<EditVideoCampaign />}
                />

                <Route path="chatbots" element={<ChatbotTable />} />
                <Route
                  path="chatbots/edit/:chatbotId"
                  element={<EditChatbot />}
                />
                <Route path="chatbots/edit" element={<ChatbotTable />} />
                <Route path="chatbots/add" element={<EditChatbot />} />

                <Route path="home" element={<AgencyHome />} />
                <Route path="agencies/:agencyId" element={<AgencyHome />} />

                <Route path="billing" element={<BillingPage />} />
                <Route
                  path="/agencies/:agencyId/billing"
                  element={<BillingPage />}
                />

                <Route path="insights" element={<ConnectionInsightsPage />} />
                <Route
                  path="/agencies/:agencyId/insights"
                  element={<ConnectionInsightsPage />}
                />

                <Route path="history" element={<ChangeHistoryTable />} />
                <Route
                  path="agencies/:agencyId/history"
                  element={<ChangeHistoryTable />}
                />

                <Route path="start" element={<WizardStart />} />
                <Route
                  path="agencies/:agencyId/start"
                  element={<WizardStart />}
                />

                <Route path="start/selection" element={<ServiceSelection />} />
                <Route
                  path="agencies/:agencyId/start/selection"
                  element={<ServiceSelection />}
                />

                <Route
                  path="start/selection/channel"
                  element={<ChannelSelection />}
                />
                <Route
                  path="agencies/:agencyId/start/selection/channel"
                  element={<ChannelSelection />}
                />

                <Route
                  path="start/selection/channel/setup"
                  element={<SetupPage />}
                />
                <Route
                  path="agencies/:agencyId/start/selection/channel/setup"
                  element={<SetupPage />}
                />

                <Route
                  path="start/selection/channel/setup/review"
                  element={<ReviewSetup />}
                />
                <Route
                  path="agencies/:agencyId/start/selection/channel/setup/review"
                  element={<ReviewSetup />}
                />

                <Route
                  path="start/selection/channel/setup/review/complete"
                  element={<Complete />}
                />
                <Route
                  path="agencies/:agencyId/start/selection/channel/setup/review/complete"
                  element={<Complete />}
                />

                <Route path="holidays" element={<HolidaysPage />} />
                <Route
                  path="agencies/:agencyId/holidays"
                  element={<HolidaysPage />}
                />

                <Route path="setup" element={<ManageServicesPage />} />
                <Route
                  path="updating-services"
                  element={<ManageServicesRedirect />}
                />
                <Route
                  path="updating-services/updating-services"
                  element={<ManageServicesRedirect />}
                />
                <Route
                  path="setup/updating-services/updating-services"
                  element={<ManageServicesRedirect />}
                />
                <Route
                  path="setup/updating-services"
                  element={<ManageServicesRedirect />}
                />
                <Route
                  path="agencies/:agencyId/updating-services"
                  element={<ManageServicesRedirect />}
                />
                <Route
                  path="agencies/:agencyId/updating-services/updating-services"
                  element={<ManageServicesRedirect />}
                />
                <Route path="setup/:success" element={<ManageServicesPage />} />
                <Route
                  path="setup/:success/updating-services"
                  element={<ManageServicesRedirect />}
                />
                <Route
                  path="setup/success/updating-services"
                  element={<ManageServicesRedirect />}
                />
                <Route
                  path="agencies/:agencyId/setup"
                  element={<ManageServicesPage />}
                />
                <Route
                  path="agencies/:agencyId/setup/:success"
                  element={<ManageServicesPage />}
                />
                <Route
                  path="agencies/:agencyId/setup/success/updating-services"
                  element={<ManageServicesRedirect />}
                />
                <Route
                  path="agencies/:agencyId/setup/success/updating-services/updating-services"
                  element={<ManageServicesRedirect />}
                />
                <Route
                  path="agencies/:agencyId/setup/:success/updating-services"
                  element={<ManageServicesRedirect />}
                />
                <Route
                  path="agencies/:agencyId/setup/:success/updating-services/updating-services"
                  element={<ManageServicesRedirect />}
                />
                <Route
                  path="agencies/:agencyId/setup/success/updating-services"
                  element={<ManageServicesRedirect />}
                />
                <Route
                  path="agencies/:agencyId/setup/success/updating-services/updating-services"
                  element={<ManageServicesRedirect />}
                />
              </Route>
            </Routes>
          </ToasterProvider>
        </NFCCProvider>
      </BrowserRouter>
    </Suspense>
  );
};
