import { FC } from "react";

import { Box } from "@twilio-paste/core/box";
import { Heading } from "@twilio-paste/core/heading";
import { Button } from "@twilio-paste/core/button";
import { Anchor } from "@twilio-paste/core/anchor";
import { LOGO } from "../../constants";

export const LogoutPage: FC = () => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        marginY="space200"
        alignItems="center"
      >
        <Box
          borderColor="colorBorder"
          borderWidth="borderWidth10"
          borderStyle="solid"
          width={["100%", "30rem", "30rem"]}
          borderRadius="borderRadius30"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            rowGap="space100"
            paddingBottom="space60"
          >
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              style={{ background: "#067fa7" }}
              justifyContent="center"
              paddingY="space60"
            >
              <img width="50%" src={LOGO}></img>
            </Box>

            <Heading as="h2" variant="heading20">
              You have logged out
            </Heading>

            <Button variant="secondary">
              <Anchor href="/login">Login Again</Anchor>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
