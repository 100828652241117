import { useContext } from "react";
import {
  StateContext,
  StateContextType,
} from "../../components/AppStateProvider/AppStateProvider";

export const useAppState = (): StateContextType => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
};
