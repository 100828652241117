/* eslint @typescript-eslint/no-var-requires: "off" */

import { Flex } from "@twilio-paste/flex";
import { Label } from "@twilio-paste/label";
import { Input } from "@twilio-paste/input";
import { Box } from "@twilio-paste/box";
import { Button } from "@twilio-paste/button";
import { Stack } from "@twilio-paste/stack";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { Chatbot, Services, VideoCampaigns } from "@ciptex/nfcc";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { Spinner } from "@twilio-paste/core/dist/spinner";
import { FORM_BACKGROUND } from "../../constants";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useParams, useNavigate } from "react-router-dom";
import { useToasterContext } from "../../hooks/useToasterContext";
import { Checkbox, CheckboxGroup, Radio, RadioGroup } from "@twilio-paste/core";
import { TextArea } from "@twilio-paste/textarea";
import { SubtitleText } from "../HeaderTitleText/SubtitleText";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { Select, Option } from "@twilio-paste/core/select";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import { HelpText } from "@twilio-paste/core/help-text";
import { DeleteIcon } from "@twilio-paste/icons/esm/DeleteIcon";

export const EditChatbot: FC = () => {
  const { chatbotId } = useParams();
  const navigate = useNavigate();
  const [chatbot, setChatbot] = useState<Chatbot>();
  const [services, setServices] = useState<any[]>();
  const [videoCampaigns, setVideoCampaigns] = useState<any[]>();
  const [loaded, setLoaded] = useState<boolean>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isConnectionTypeError, setIsConnectionTypeError] =
    useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _ = require("lodash");

  const {
    getChatbot,
    updateChatbot,
    listServices,
    createChatbot,
    listVideoCampaigns,
  } = useNFCCContext();

  const { toaster } = useToasterContext();

  const handleChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setChatbot({
      ...chatbot,
      [target.name]: target.value,
    });
  };

  const handleChangeService = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (chatbot) {
      if (chatbot.services) {
        const s = (chatbot.services ?? []).map((x) => x);
        const i = parseInt(target.id.split("#")[1]);
        console.log(target);
        if (target.name == "serviceId") {
          const sNew: any = { ...s[i], [target.name]: parseInt(target.value) };
          s.splice(i, 1, sNew);
          console.log(s);

          setChatbot({ ...chatbot, services: s });
        } else {
          const sNew: any = { ...s[i], [target.name]: target.value };
          s.splice(i, 1, sNew);
          console.log(s);

          setChatbot({ ...chatbot, services: s });
        }
      }
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    (async () => {
      try {
        if (chatbot) {
          let isServiceError = false;
          setIsError(false);
          setIsConnectionTypeError(false);

          const s = [];
          // remove any blank services
          const c = { ...chatbot };
          if (chatbot.services) {
            for (const se of chatbot.services) {
              if (Object.keys(se).length != 0) {
                s.push(se);
              }
            }
            c.services = s;
          } else {
            setIsError(true);
            isServiceError = true;
          }

          if (!(chatbot as any).connectionTypes) {
            setIsConnectionTypeError(true);
            return;
          }

          if (isServiceError) {
            return;
          }

          if (chatbotId) {
            console.log("update", c, chatbot);
            await updateChatbot(parseInt(chatbotId ? chatbotId : ""), c);
            setLoaded(true);
            toaster.push({
              message: "Chatbot details updated",
              variant: "success",
              dismissAfter: 4000,
            });
            navigate("/chatbots");
          } else {
            console.log("create", c, chatbot);
            await createChatbot(c);
            setLoaded(true);
            toaster.push({
              message: "Chatbot created",
              variant: "success",
              dismissAfter: 4000,
            });
            navigate("/chatbots");
          }
        }
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Chatbot details could not be updated",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    })();
  };

  const onCheckboxChange = ({ target }: any) => {
    if (target.checked) {
      if (chatbot) {
        const l = ((chatbot as any).connectionTypes ?? []).map((x: any) => x);
        l.push(target.value);
        const c: any = { ...chatbot, ["connectionTypes"]: l };
        setChatbot(c);
      }
    } else {
      if (chatbot) {
        const l: any[] = ((chatbot as any).connectionTypes ?? []).filter(
          (i: any) => i !== target.value,
        );
        const c: any = { ...chatbot, ["connectionTypes"]: l };
        setChatbot(c);
      }
    }
  };

  useEffect(() => {
    if (!chatbotId && location.pathname.split("/").pop() !== "add") {
      navigate("/chatbots");
      return;
    }

    (async () => {
      try {
        if (chatbotId) {
          const data: Chatbot = await getChatbot(
            parseInt(chatbotId ? chatbotId : ""),
          );
          setChatbot(data);
        } else {
          setChatbot({});
        }

        const s: Services = await listServices();
        const sLite = (s ?? []).map(({ serviceId, name }) => {
          return { serviceId, name };
        });
        setServices(sLite);

        const v: VideoCampaigns = await listVideoCampaigns();
        setVideoCampaigns(v);

        setLoaded(true);
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Could not retrieve data",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    })();
  }, []);

  useEffect(() => {
    console.log("chatbot change!", chatbot);
  }, [chatbot]);

  const handleTextChange = (e: any) => {
    setChatbot({
      ...chatbot,
      [e.target.name]: e.target.value,
    });
  };

  const formatBoolean = (v: number) => {
    if (v === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  const addRow = () => {
    console.log("addRow");
    if (chatbot) {
      const s = (chatbot.services ?? []).map((x) => x);
      s.push({});
      setChatbot({ ...chatbot, services: s });
    }
  };

  const removeRow = (rowIndex: number) => {
    console.log("removeRow", rowIndex);
    if (chatbot) {
      const s = (chatbot.services ?? []).map((x) => x);
      s.splice(rowIndex, 1);
      setChatbot({ ...chatbot, services: s });
    }
  };

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexDirection="column"
      style={{ background: FORM_BACKGROUND }}
      padding="space60"
      borderRadius="borderRadius30"
      marginBottom="space60"
      marginTop="space40"
      borderStyle="solid"
      borderWidth="borderWidth10"
      borderColor="colorBorder"
    >
      <Box>
        <HeaderTitleText
          titleText={
            (location.pathname.split("/").pop() !== "add" && "Edit chatbot") ||
            "Add chatbot"
          }
        />
        {chatbot && loaded ? (
          <form onSubmit={(e) => handleSubmit(e)}>
            <Box paddingRight="space60">
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                columnGap="space80"
                width="95%"
              >
                <Box width="50%" paddingBottom="space60" paddingRight="space60">
                  <Label htmlFor="description" required>
                    Label
                  </Label>
                  <TextArea
                    onChange={(e) => {
                      handleTextChange(e);
                    }}
                    required
                    placeholder="Add text"
                    id="label"
                    name="label"
                    defaultValue={chatbot.label}
                  />
                </Box>
                <Box width="45%" paddingBottom="space60" paddingRight="space60">
                  <Label htmlFor="name" required>
                    Location
                  </Label>
                  <Input
                    required
                    placeholder="Add URL where this will be hosted, excluding /es"
                    id="location"
                    name="location"
                    type="text"
                    onChange={handleChange}
                    defaultValue={chatbot.location}
                  ></Input>
                </Box>
                {loaded && (
                  <Box
                    width="25%"
                    paddingBottom="space60"
                    paddingRight="space60"
                  >
                    <Label
                      htmlFor="videoCampaignId"
                      required={(
                        (chatbot as any).connectionTypes ?? []
                      ).includes("Video")}
                    >
                      Video Campaign
                    </Label>
                    <Select
                      id="videoCampaignId"
                      required={(
                        (chatbot as any).connectionTypes ?? []
                      ).includes("Video")}
                      name="videoCampaignId"
                      value={(chatbot as any).videoCampaignId}
                      onChange={handleChange}
                    >
                      <Option key={-1} value={""}>
                        -- Select a video campaign --
                      </Option>

                      {videoCampaigns && loaded
                        ? videoCampaigns &&
                          videoCampaigns.map((s: any, index: number) => (
                            <Option key={index} value={s.videoCampaignId}>
                              {s.name}
                            </Option>
                          ))
                        : null!}
                    </Select>
                  </Box>
                )}
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                columnGap="space160"
                paddingTop="space60"
              >
                <Box width="20%" paddingBottom="space60" paddingRight="space60">
                  <RadioGroup
                    required
                    name="showQ1"
                    value={formatBoolean(chatbot.showQ1 ?? 1)}
                    legend="Show Question 1?"
                    // onChange={handleChange}
                    onChange={(e) => {
                      setChatbot({
                        ...chatbot,
                        ["showQ1"]: e === "Yes" ? 1 : 0,
                      });
                    }}
                  >
                    <Radio id="showQ1yes" value="Yes">
                      Yes
                    </Radio>
                    <Radio id="showQ1no" value="No">
                      No
                    </Radio>
                  </RadioGroup>
                </Box>
                <Box width="20%" paddingBottom="space60" paddingRight="space60">
                  <RadioGroup
                    required
                    name="useProd"
                    value={formatBoolean(chatbot.useProd ?? 1)}
                    legend="Use Production API?"
                    // onChange={handleChange}
                    onChange={(e) => {
                      setChatbot({
                        ...chatbot,
                        ["useProd"]: e === "Yes" ? 1 : 0,
                      });
                    }}
                  >
                    <Radio id="useProdyes" value="Yes">
                      Yes
                    </Radio>
                    <Radio id="useProdno" value="No">
                      No
                    </Radio>
                  </RadioGroup>
                </Box>

                <Box width="50%" paddingBottom="space60" paddingRight="space60">
                  <Label htmlFor="avLangs" required>
                    Connection Types
                  </Label>
                  <CheckboxGroup name="langscbgroup" legend="">
                    <CheckboxGroup name="avLangs" legend="" helpText="">
                      <Checkbox
                        key="Phone"
                        id="Phone"
                        checked={(
                          (chatbot as any).connectionTypes ?? []
                        ).includes("Phone")}
                        value="Phone"
                        name="EngPhonelish"
                        onClick={(e) => onCheckboxChange(e)}
                      >
                        Phone (Click-to-call)
                      </Checkbox>
                      <Checkbox
                        key="Online"
                        id="Online"
                        checked={(
                          (chatbot as any).connectionTypes ?? []
                        ).includes("Online")}
                        value="Online"
                        name="Online"
                        onClick={(e) => onCheckboxChange(e)}
                      >
                        Online (includes booking)
                      </Checkbox>
                      <Checkbox
                        key="Video"
                        id="Video"
                        checked={(
                          (chatbot as any).connectionTypes ?? []
                        ).includes("Video")}
                        value="Video"
                        name="Video"
                        onClick={(e) => onCheckboxChange(e)}
                      >
                        Video (includes booking)
                      </Checkbox>
                    </CheckboxGroup>
                  </CheckboxGroup>
                  {isConnectionTypeError && (
                    <HelpText id="connectiontype_error" variant="error">
                      Please configure at least one connection type
                    </HelpText>
                  )}
                </Box>
              </Box>
            </Box>

            <Box width="95%">
              <Box
                paddingRight="space120"
                paddingTop="space120"
                width="100%"
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Box>
                  <SubtitleText subtitleText="Services" />
                </Box>

                <Button variant="secondary" onClick={addRow}>
                  <PlusIcon decorative={false} title="Add new service" />
                  Add Service
                </Button>
              </Box>

              <HelpText id="services_help_text">
                If you&apos;re setting question 1 to visible, this will add
                Credit Counseling and Overall Budget and Financial Review to the
                first question. You should add these two services below. Please
                also include at least one other service.
              </HelpText>
              <HelpText id="services_help_text1">
                If question 1 is invisible, you can configure only one service
                if required.
              </HelpText>

              {isError && (
                <HelpText id="services_error" variant="error">
                  Please configure at least one service
                </HelpText>
              )}
              <Box
                width="100%"
                paddingBottom="space120"
                paddingRight="space120"
                paddingTop="space120"
              >
                <Table aria-label="Chatbot table" striped>
                  <THead>
                    <Tr>
                      <Th></Th>
                      <Th width="25%">Service</Th>
                      <Th width="35%">English</Th>
                      <Th width="35%">Spanish</Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {chatbot &&
                      (chatbot.services ?? []).map(
                        (x: any, rowIndex: number) => (
                          <Tr key={rowIndex}>
                            <Td>
                              <Button
                                id={"delete#" + rowIndex}
                                variant="destructive"
                                onClick={() => removeRow(rowIndex)}
                              >
                                <DeleteIcon
                                  decorative={false}
                                  title="Delete Phone Number"
                                />
                              </Button>
                            </Td>
                            <Td>
                              <Select
                                id={"service#" + rowIndex}
                                name="serviceId"
                                value={x.serviceId}
                                onChange={handleChangeService}
                              >
                                <Option key={-1} value={""}>
                                  -- Select a service --
                                </Option>

                                {services && loaded
                                  ? services &&
                                    services.map((s: any, index: number) => (
                                      <Option key={index} value={s.serviceId}>
                                        {s.name}
                                      </Option>
                                    ))
                                  : null!}
                              </Select>
                            </Td>
                            <Td>
                              <Input
                                id={"value#" + rowIndex}
                                name="value"
                                type="text"
                                onChange={handleChangeService}
                                defaultValue={x.value}
                              ></Input>
                            </Td>
                            <Td>
                              <Input
                                id={"spanishValue#" + rowIndex}
                                name="spanishValue"
                                type="text"
                                onChange={handleChangeService}
                                defaultValue={x.spanishValue}
                              ></Input>
                            </Td>
                          </Tr>
                        ),
                      )}
                  </TBody>
                </Table>
              </Box>
            </Box>

            <Stack orientation="horizontal" spacing="space200">
              <Button variant="secondary" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Save
              </Button>
            </Stack>
          </form>
        ) : (
          <Flex vAlignContent="center" hAlignContent="center">
            <Spinner size="sizeIcon110" decorative={false} title="Loading" />
          </Flex>
        )}
      </Box>
    </Box>
  );
};
