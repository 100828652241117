/* eslint @typescript-eslint/no-var-requires: "off" */

import { Flex } from "@twilio-paste/flex";
import { Heading } from "@twilio-paste/heading";
import { Label } from "@twilio-paste/label";
import { Input } from "@twilio-paste/input";
import { Box } from "@twilio-paste/box";
import { Button } from "@twilio-paste/button";
import { Stack } from "@twilio-paste/stack";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { Service, Categories, Agencies, ServiceNew } from "@ciptex/nfcc";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { FORM_BACKGROUND } from "../../constants";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useNavigate } from "react-router-dom";
import { useToasterContext } from "../../hooks/useToasterContext";
import { Select } from "@twilio-paste/core/select";
import { Option, Checkbox, CheckboxGroup } from "@twilio-paste/core";
import { HelpText } from "@twilio-paste/core/help-text";
import { Scroller } from "../Scroller/Scroller";

export const AddService: FC = () => {
  const navigate = useNavigate();
  const [service, setService] = useState<ServiceNew>();
  const [categories, setCategories] = useState<Categories>();
  const [agencies, setAgencies] = useState<Agencies>();
  const [loaded, setLoaded] = useState<boolean>();

  const [categoryIdError, setcategoryIdError] = useState<boolean>(false);
  const [routingTypeError, setRoutingTypeError] = useState<boolean>(false);

  const _ = require("lodash");

  const { createService, listCategories, listAgencies } = useNFCCContext();

  const { toaster } = useToasterContext();

  const handleChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setService({
      ...service,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    (async () => {
      try {
        if (service) {
          let error = false;
          if (service?.category === undefined || service?.category === "") {
            setcategoryIdError(true);
            error = true;
          } else {
            setcategoryIdError(false);
          }
          if (
            service?.routingOutputType === undefined ||
            service?.routingOutputType === ""
          ) {
            setRoutingTypeError(true);
            error = true;
          } else {
            setRoutingTypeError(false);
          }
          if (error) {
            window.scrollTo(0, 0);
          } else {
            const ag: any[] = [];
            for (const a of service.availableAgencies ?? []) {
              const agency = (agencies ?? []).filter(
                (i) => i.name === a.name,
              )[0];
              if (agency) {
                if (
                  !(ag ?? [])
                    .map(({ agencyId }) => agencyId)
                    .includes(agency.agencyId)
                ) {
                  ag.push(agency);
                }
              }
            }
            const newService: any = { ...service, ["availableAgencies"]: ag };
            setService(newService);

            await createService(newService);
            setLoaded(true);
            toaster.push({
              message: "Service details updated",
              variant: "success",
              dismissAfter: 4000,
            });
            navigate("/services");
          }
        }
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Service details could not be updated",
          variant: "success",
          dismissAfter: 4000,
        });
      }
    })();
  };

  useEffect(() => {
    (async () => {
      try {
        const cats: Categories = await listCategories();
        setCategories(cats);

        const ags: Agencies = await listAgencies();
        setAgencies(ags);

        setLoaded(true);
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Could not retrieve data",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    })();
  }, []);

  const onCheckboxChangeAgencies = ({ target }: any) => {
    if (target.checked) {
      if (service) {
        const avAgencies: any[] = [];
        for (const a of service.availableAgencies ?? []) {
          avAgencies.push(a);
        }
        avAgencies.push({ name: target.value });
        const a: any[] = (avAgencies ?? []).filter((i) => i.name !== null);
        const newService: Service = { ...service, ["availableAgencies"]: a };
        setService(newService);
      }
    } else {
      if (service) {
        const avAgencies: any[] = (service.availableAgencies ?? []).filter(
          (i) => i.name !== target.value,
        );
        const newService: Service = {
          ...service,
          ["availableAgencies"]: avAgencies,
        };
        setService(newService);
      }
    }
  };

  const onCheckboxSetCalendly = ({ target }: any) => {
    if (service) {
      const newService: any = {
        ...service,
        enableBooking: target.checked,
      };
      setService(newService);
    }
  };

  const onCheckboxChangeLanguages = ({ target }: any) => {
    if (target.checked) {
      if (service) {
        const l = (service.availableLanguages ?? []).map((x) => x);
        l.push(target.value);
        const newService: Service = { ...service, ["availableLanguages"]: l };
        setService(newService);
      }
    } else {
      if (service) {
        const l: any[] = (service.availableLanguages ?? []).filter(
          (i) => i !== target.value,
        );
        const newService: Service = { ...service, ["availableLanguages"]: l };
        setService(newService);
      }
    }
  };

  const onCheckboxChangeChannels = ({ target }: any) => {
    if (target.checked) {
      if (service) {
        const l = (service.availableConnectionTypes ?? []).map((x) => x);
        l.push(target.value);
        const newService: Service = {
          ...service,
          ["availableConnectionTypes"]: l,
        };
        setService(newService);
      }
    } else {
      if (service) {
        const l: any[] = (service.availableConnectionTypes ?? []).filter(
          (i) => i !== target.value,
        );
        const newService: Service = {
          ...service,
          ["availableConnectionTypes"]: l,
        };
        setService(newService);
      }
    }
  };

  const setAllChecked = (checked: boolean) => {
    if (checked) {
      if (service && agencies) {
        const avAgencies: any[] = (agencies ?? []).map(({ name }) => name);

        const a: any[] = [];
        for (const ag of avAgencies) {
          a.push({ name: ag });
        }

        const newService: Service = { ...service, ["availableAgencies"]: a };
        setService(newService);
      }
    } else {
      if (service) {
        const newService: Service = { ...service, ["availableAgencies"]: [] };
        setService(newService);
      }
    }
  };

  function allChecked(): boolean {
    try {
      if (service && agencies) {
        return _.isEmpty(
          _.xor(
            (service.availableAgencies ?? []).map(({ name }) => name),
            (agencies ?? []).map(({ name }) => name),
          ),
        );
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexDirection="column"
      style={{ background: FORM_BACKGROUND }}
      padding="space60"
      borderRadius="borderRadius30"
      marginBottom="space60"
      marginTop="space40"
      borderStyle="solid"
      borderWidth="borderWidth10"
      borderColor="colorBorder"
    >
      <Box>
        <HeaderTitleText titleText="Create Service" />

        <form onSubmit={(e) => handleSubmit(e)}>
          <Box paddingRight="space60">
            <Flex>
              <Box width="30%" paddingBottom="space60" paddingRight="space60">
                <Label htmlFor="name" required>
                  Name
                </Label>
                <Input
                  required
                  id="name"
                  name="name"
                  type="text"
                  onChange={handleChange}
                ></Input>
              </Box>
              <Box width="30%" paddingBottom="space60" paddingRight="space60">
                <Label required htmlFor="category">
                  Category
                </Label>
                <Select
                  required
                  onChange={handleChange}
                  name="category"
                  id="category"
                >
                  <Option value={""}>Choose an option</Option>
                  {categories && loaded
                    ? categories &&
                      categories.map((category: any, index: number) => (
                        <Option key={index} value={category.label}>
                          {category.label}
                        </Option>
                      ))
                    : null!}
                </Select>
                {categoryIdError && (
                  <HelpText variant="error">
                    Cannot submit without a Category
                  </HelpText>
                )}
              </Box>
              <Box width="30%" paddingBottom="space60" paddingRight="space60">
                <Label required htmlFor="clientId">
                  Routing Output Type
                </Label>
                <Select
                  required
                  onChange={handleChange}
                  name="routingOutputType"
                  id="routingOutputType"
                >
                  <Option value={""}>Choose an option</Option>
                  <Option value={"RoundRobin"}>Round Robin</Option>
                  <Option value={"List"}>List</Option>
                </Select>
                {routingTypeError && (
                  <HelpText variant="error">
                    Cannot submit without a Routing Output Type
                  </HelpText>
                )}
              </Box>
            </Flex>
          </Box>

          <Box
            borderRadius="borderRadius20"
            borderStyle="solid"
            borderWidth="borderWidth10"
            borderColor="colorBorder"
            padding="space60"
            display="flex"
            flexDirection="column"
          >
            <Scroller />

            <Heading as="h3" variant="heading30">
              Service Availability
            </Heading>
            <Box display="flex" flexDirection="row">
              <Box width="30%" paddingBottom="space60" paddingRight="space60">
                <Label htmlFor="avAgencies">Agencies</Label>
                <CheckboxGroup name="agenciescbgroup" legend="">
                  {loaded ? (
                    <CheckboxGroup name="avAgencies" legend="" helpText="">
                      <Checkbox
                        id="select_all"
                        checked={allChecked()}
                        indeterminate={
                          !allChecked() &&
                          ((service ?? {}).availableAgencies ?? []).length > 1
                        }
                        onChange={(e) => setAllChecked(e.target.checked)}
                      >
                        Select all
                      </Checkbox>
                      {agencies &&
                        agencies.map((agency: any, index: number) => (
                          <Checkbox
                            key={agency.agencyId}
                            id={agency.agencyId}
                            checked={((service ?? {}).availableAgencies ?? [])
                              .map(({ name }) => name)
                              .includes(agency.name)}
                            value={agency.name}
                            name={agency.name}
                            onClick={(e) => onCheckboxChangeAgencies(e)}
                          >
                            {agency.netsuiteRef + "  -  " + agency.name}
                          </Checkbox>
                        ))}
                    </CheckboxGroup>
                  ) : (
                    null!
                  )}
                </CheckboxGroup>
              </Box>
              <Box width="30%" paddingBottom="space60" paddingRight="space60">
                <Label htmlFor="avLangs">Languages</Label>
                <CheckboxGroup name="langscbgroup" legend="">
                  <CheckboxGroup name="avLangs" legend="" helpText="">
                    <Checkbox
                      key="English"
                      id="English"
                      checked={(
                        (service ?? {}).availableLanguages ?? []
                      ).includes("English")}
                      value="English"
                      name="English"
                      onClick={(e) => onCheckboxChangeLanguages(e)}
                    >
                      English
                    </Checkbox>
                    <Checkbox
                      key="Spanish"
                      id="Spanish"
                      checked={(
                        (service ?? {}).availableLanguages ?? []
                      ).includes("Spanish")}
                      value="Spanish"
                      name="Spanish"
                      onClick={(e) => onCheckboxChangeLanguages(e)}
                    >
                      Spanish
                    </Checkbox>
                  </CheckboxGroup>
                </CheckboxGroup>
              </Box>
              <Box width="30%" paddingBottom="space60" paddingRight="space60">
                <Label htmlFor="avLangs">Channels</Label>
                <CheckboxGroup name="conntypecbgroup" legend="">
                  <CheckboxGroup name="connTypes" legend="" helpText="">
                    <Checkbox
                      key="Phone"
                      id="Phone"
                      checked={(
                        (service ?? {}).availableConnectionTypes ?? []
                      ).includes("Phone")}
                      value="Phone"
                      name="Phone"
                      onClick={(e) => onCheckboxChangeChannels(e)}
                    >
                      Phone
                    </Checkbox>
                    <Checkbox
                      key="Online"
                      id="Online"
                      checked={(
                        (service ?? {}).availableConnectionTypes ?? []
                      ).includes("Online")}
                      value="Online"
                      name="Online"
                      onClick={(e) => onCheckboxChangeChannels(e)}
                    >
                      Online
                    </Checkbox>
                  </CheckboxGroup>
                </CheckboxGroup>
              </Box>
              <Box width="30%" paddingBottom="space60" paddingRight="space60">
                <Label htmlFor="avLangs">Booking</Label>
                <CheckboxGroup name="enableBookingCB" legend="" helpText="">
                  <Checkbox
                    key="enableBooking"
                    id="enableBooking"
                    checked={(service ?? ({} as any)).enableBooking}
                    value="enableBooking"
                    name="enableBooking"
                    onClick={(e) => onCheckboxSetCalendly(e)}
                  >
                    Enable booking through Calendly
                  </Checkbox>
                </CheckboxGroup>
              </Box>
            </Box>
          </Box>

          <Box marginY="space60">
            <Stack orientation="horizontal" spacing="space200">
              <Button variant="secondary" onClick={() => navigate("/services")}>
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Save
              </Button>
            </Stack>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
