import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { ReactElementProps } from "../../interface";

export const PrivateRoute = ({ children }: ReactElementProps) => {
  const { appState } = useAppState();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!appState.token || appState.token === "" || appState.token === null) {
      navigate("/", { replace: true });
    }
  }, [appState.token]);

  return appState.token && appState.token !== "" ? (
    children
  ) : (
    <Navigate to={`/?redirect=${pathname}`} />
  );
};
