import { User, Users, ValidationResponse, Timezone } from "@ciptex/nfcc";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { useToasterContext } from "../../hooks/useToasterContext";
import { Box } from "@twilio-paste/core/box";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { FORM_BACKGROUND } from "../../constants";
import { SubtitleText } from "../HeaderTitleText/SubtitleText";
import { Label } from "@twilio-paste/core/label";
import { Input } from "@twilio-paste/core/input";
import { Button } from "@twilio-paste/core/button";
import { Separator } from "@twilio-paste/core/separator";
import { HelpText } from "@twilio-paste/core/help-text";
import { Flex } from "@twilio-paste/core/flex";
import { Spinner } from "@twilio-paste/core/spinner";
import { Text } from "@twilio-paste/core/text";

import { Select, Option } from "@twilio-paste/core/select";

export const UserProfile: FC = () => {
  const [userId, setUserId] = useState<number>();
  const [timezones, setTimezones] = useState<any[]>();
  const { appState } = useAppState();

  const [passwordMatchExistingError, setPasswordMatchExistingError] =
    useState<boolean>(false);
  const [passwordMatchNewError, setPasswordMatchNewError] =
    useState<boolean>(false);

  const [user, setUser] = useState<User>();
  const [loaded, setLoaded] = useState<boolean>();
  const {
    getUser,
    updateUser,
    listUsersEmailFilter,
    listTimezones,
    setUserPassword,
    validateUserPassword,
  } = useNFCCContext();

  const [pwds, setPwds] = useState<any>();

  const { toaster } = useToasterContext();

  const handleTimezoneChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (timezones) {
      const tz = timezones.filter(
        (i) => i.timezoneId === parseInt(target.value),
      )[0];

      setUser({
        ...user,
        timezone: tz,
      });
    }
  };

  const handlePwdChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setPwds({
      ...pwds,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    (async () => {
      try {
        updateUser(user?.userId ?? 0, user ? user : {});
        setLoaded(true);
        toaster.push({
          message: "User profile updated",
          variant: "success",
          dismissAfter: 4000,
        });
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "User profile could not be updated",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    })();
  };

  const handleTimezoneSubmit = async (e: any) => {
    e.preventDefault();
    (async () => {
      try {
        updateUser(user?.userId ?? 0, user ? user : {});
        setLoaded(true);
        toaster.push({
          message: "User timezone updated",
          variant: "success",
          dismissAfter: 4000,
        });
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "User timezone could not be updated",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    })();
  };

  const handlePwdSubmit = async (e: any) => {
    setPasswordMatchExistingError(false);
    setPasswordMatchNewError(false);
    e.preventDefault();
    (async () => {
      try {
        // validate existing password
        const validationResponse: ValidationResponse =
          await validateUserPassword({
            username: appState.email,
            password: pwds.currentPassword,
          });

        if (!validationResponse.passwordMatch) {
          setPasswordMatchExistingError(true);
        } else {
          if (pwds.newPassword !== pwds.retypePassword) {
            setPasswordMatchNewError(true);
          } else {
            setUserPassword({
              userId: user?.userId,
              password: pwds.newPassword,
            });
            // All good, update the password!
            updateUser(user?.userId ?? 0, user ? user : {});
            setLoaded(true);
            toaster.push({
              message: "Password updated",
              variant: "success",
              dismissAfter: 4000,
            });
          }
        }
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "User profile could not be updated",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    })();
  };

  useEffect(() => {
    (async () => {
      try {
        const users: Users = await listUsersEmailFilter(
          encodeURI(appState.email),
        );
        setUser(users[0]);
        setUserId(users[0].userId);
        const data: User = await getUser(users[0].userId ?? 0);
        setUser(data);

        const t: Timezone = await listTimezones();
        const tzs: Timezone = [];
        for (const i of t) {
          if (!i.nameLong?.includes("Daylight")) {
            tzs.push(i);
          }
        }

        setTimezones(tzs);

        setLoaded(true);
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Could not retrieve data",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    })();
  }, []);

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexDirection="column"
      style={{ background: FORM_BACKGROUND }}
      padding="space60"
      borderRadius="borderRadius30"
      marginBottom="space60"
      marginTop="space60"
      borderStyle="solid"
      borderWidth="borderWidth10"
      borderColor="colorBorder"
      width="100%"
    >
      <HeaderTitleText titleText="User Profile" />

      {/* User details change form */}
      {user && loaded ? (
        <Box>
          <SubtitleText subtitleText="Password Change" />

          <form onSubmit={(e) => handlePwdSubmit(e)}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              marginBottom="space60"
            >
              <Box width="25%">
                <Label required htmlFor="currentPassword">
                  Current Password
                </Label>
                <Input
                  required
                  onChange={handlePwdChange}
                  id="currentPassword"
                  name="currentPassword"
                  type="password"
                ></Input>
                {passwordMatchExistingError && (
                  <HelpText variant="error">
                    Password does not match existing password
                  </HelpText>
                )}
              </Box>

              <Box width="25%">
                <Label required htmlFor="newPassword">
                  New Password
                </Label>
                <Input
                  required
                  onChange={handlePwdChange}
                  id="newPassword"
                  name="newPassword"
                  type="password"
                ></Input>
                {passwordMatchNewError && (
                  <HelpText variant="error">
                    New passwords do not match
                  </HelpText>
                )}
              </Box>

              <Box width="25%">
                <Label required htmlFor="retypePassword">
                  Retype New Password
                </Label>
                <Input
                  required
                  onChange={handlePwdChange}
                  id="retypePassword"
                  name="retypePassword"
                  type="password"
                ></Input>
              </Box>
              <Box display="flex" alignItems="flex-end">
                <Button variant="primary" type="submit">
                  Change Password
                </Button>
              </Box>
            </Box>
          </form>

          <Separator orientation="horizontal" />

          <form onSubmit={(e) => handleTimezoneSubmit(e)}>
            <Box
              display="flex"
              flexDirection="row"
              marginTop="space60"
              marginBottom="space60"
            >
              <Box display="flex" flexDirection="column">
                <SubtitleText subtitleText="Timezone" />

                <Box padding="space10">
                  <Text as="p" fontSize="fontSize20">
                    Change timezone to view all settings in selected time
                  </Text>
                </Box>
                <Box paddingBottom="space60"></Box>
                <Label htmlFor="timezone">Timezone</Label>
                {loaded && (
                  <Select
                    id="timezone"
                    name="timezone"
                    onChange={handleTimezoneChange}
                    value={(user?.timezone as any).timezoneId?.toString()}
                  >
                    {timezones && loaded
                      ? timezones &&
                        timezones.map((s: any, index: number) => (
                          <Option key={index} value={s.timezoneId}>
                            {s.name}
                          </Option>
                        ))
                      : null!}
                  </Select>
                )}
              </Box>

              <Box display="flex" alignItems="flex-end" marginLeft="space60">
                <Button variant="primary" type="submit">
                  Change Timezone
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      ) : (
        <Flex vAlignContent="center" hAlignContent="center">
          <Spinner size="sizeIcon110" decorative={false} title="Loading" />
        </Flex>
      )}
    </Box>
  );
};
