import { FC } from "react";
import { Box } from "@twilio-paste/core/box";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "@twilio-paste/core/breadcrumb";
import { Text } from "@twilio-paste/core/text";
import { sentenceCase } from "../../functions/sentence-case";

export const BreadcrumbMaker: FC = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/");
  const navigate = useNavigate();

  const createBreadcrumbItems = (): FC => {
    const items: any = [];
    pathnames.forEach((pathname: string, index: number) => {
      if (pathname !== "") {
        items.push(
          <BreadcrumbItem
            key={index}
            onClick={() =>
              navigate(`${pathnames.slice(0, index + 1).join("/")}`)
            }
          >
            <Text
              as="a"
              style={{ color: "rgb(2,99,224)" }}
              _hover={{ textDecoration: "underline", cursor: "pointer" }}
            >
              {sentenceCase(pathname)}
            </Text>
          </BreadcrumbItem>,
        );
      }
    });
    return items;
  };

  return (
    <Box marginBottom="space60">
      <Breadcrumb>{createBreadcrumbItems()}</Breadcrumb>
    </Box>
  );
};
