import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { Services } from "@ciptex/nfcc";
import { Box } from "@twilio-paste/core/box";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { Label } from "@twilio-paste/core/label";
import { DownloadIcon } from "@twilio-paste/icons/esm/DownloadIcon";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { DateTime } from "luxon";
// import { useAppState } from "../../hooks/useAppState/useAppState";
import { useToasterContext } from "../../hooks/useToasterContext";
import { useNavigate } from "react-router-dom";
import { EditIcon } from "@twilio-paste/icons/esm/EditIcon";
import { HelpText } from "@twilio-paste/core/help-text";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import { Button } from "@twilio-paste/core/button";
import { AlertDialog } from "@twilio-paste/core/alert-dialog";
import { Flex } from "@twilio-paste/core/flex";
import { Select, Option } from "@twilio-paste/core/select";
import { FilterIcon } from "@twilio-paste/icons/esm/FilterIcon";
import { Stack } from "@twilio-paste/core/stack";
import { PlayIcon } from "@twilio-paste/icons/esm/PlayIcon";
import { PauseIcon } from "@twilio-paste/icons/esm/PauseIcon";
import { ListItem, UnorderedList } from "@twilio-paste/core/list";
import { Scroller } from "../Scroller/Scroller";

export const ServicesTable: FC = () => {
  const [services, setServices] = useState<Services>();
  const [filteredServices, setFilteredServices] = useState<Services>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [categoryFilterValue, setCategoryFilterValue] =
    useState<string>("All Categories");
  const [availableLanguagesFilterValue, setAvailableLanguagesFilterValue] =
    useState<string>("All Languages");
  const [
    availableConnectionTypesFilterValue,
    setAvailableConnectionTypesFilterValue,
  ] = useState<string>("All Connection Types");

  const { listServices, deleteService, updateService } = useNFCCContext();
  const { toaster } = useToasterContext();

  // confirmation modal state management
  const [toDelete, setToDelete] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = (serviceId: number, rowIndex: number) => {
    setToDelete({ serviceId, rowIndex });
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const navigate = useNavigate();

  const getDate = () => {
    const today = DateTime.local();
    const todayDate = today.toFormat("dd-MM-yyyy-HH-mm-ss");
    return todayDate;
  };

  const exportTableData = () => {
    if (filteredServices) {
      const csvData = [
        [
          "Category",
          "Service",
          "Available Languages",
          "Available Channels",
          "Routing Output Type",
        ],
        ...filteredServices.map(
          ({
            category,
            name,
            availableLanguages,
            availableConnectionTypes,
            routingOutputType,
          }) => [
            category,
            name,
            availableLanguages?.join(";  "),
            availableConnectionTypes?.join(";  "),
            routingOutputType,
          ],
        ),
      ];

      const csv = csvData.map((row) => row.join(",")).join("\n");
      const csvBlob = new Blob([csv], { type: "text/csv" });
      const csvUrl = URL.createObjectURL(csvBlob);
      const downloadLink = document.createElement("a");
      downloadLink.href = csvUrl;
      downloadLink.download = `services-${getDate()}.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const data: Services = await listServices();

        const s = [];
        for (const d of data) {
          let langs = "";
          if (d.availableLanguages) {
            langs = d.availableLanguages.join(", ");
          }

          let ch = "";
          if (d.availableConnectionTypes) {
            ch = d.availableConnectionTypes.join(", ");
          }

          const d1 = { ...d, langs: langs, channels: ch };
          s.push(d1);
        }

        setServices(s);
        setFilteredServices(s);
        if (s) {
          const cats = [
            ...new Set(data.map((item) => item.category ?? "") ?? []),
          ];

          setCategories(cats);
        }

        setLoaded(true);
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Could not retrieve service data",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    })();
  }, []);

  const handleFilterChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (target.name === "category") {
      setCategoryFilterValue(target.value);
    } else if (target.name === "connectionType") {
      setAvailableConnectionTypesFilterValue(target.value);
    } else if (target.name === "language") {
      setAvailableLanguagesFilterValue(target.value);
    }
  };

  const applyFilters = () => {
    if (services) {
      let filteredServs = services;

      if (categoryFilterValue !== "All Categories") {
        filteredServs = filteredServs.filter((a) =>
          (a.category ?? "").includes(categoryFilterValue),
        );
      }

      if (availableLanguagesFilterValue !== "All Languages") {
        filteredServs = filteredServs.filter((a) =>
          (a.availableLanguages ?? []).includes(availableLanguagesFilterValue),
        );
      }

      if (availableConnectionTypesFilterValue !== "All Connection Types") {
        filteredServs = filteredServs.filter((a) =>
          (a.availableConnectionTypes ?? []).includes(
            availableConnectionTypesFilterValue,
          ),
        );
      }

      setFilteredServices(filteredServs);
    }
  };

  const handleDelete = async (serviceId: number, rowIndex: number) => {
    if (services) {
      try {
        await deleteService(serviceId);
        toaster.push({
          message: "Service deactivated successfully",
          variant: "success",
          dismissAfter: 4000,
        });

        const service: any = {
          ...services.filter((i) => i.serviceId === serviceId)[0],
          ["isActive"]: 0,
        };

        const servicesNew = services.map((x) => x);
        servicesNew.splice(rowIndex, 1, service);

        setServices(servicesNew);
        setFilteredServices(servicesNew);
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Service could not be deactivated",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    }
  };

  const handleUndelete = async (serviceId: number, rowIndex: number) => {
    if (services) {
      try {
        const service: any = {
          ...services.filter((i) => i.serviceId === serviceId)[0],
          ["isActive"]: 1,
        };

        await updateService(serviceId, service);
        toaster.push({
          message: "Agency reactivated successfully",
          variant: "success",
          dismissAfter: 4000,
        });
        const servicesNew = services.map((x) => x);
        servicesNew.splice(rowIndex, 1, service);
        setServices(servicesNew);
        setFilteredServices(servicesNew);
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Service could not be reactivated",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    }
  };

  const handleCloseWithDelete = () => {
    setIsOpen(false);
    handleDelete(toDelete.serviceId, toDelete.rowIndex);
    setToDelete({});
  };

  return (
    <Box width="100%">
      <HeaderTitleText titleText="Services" />

      <UnorderedList>
        <ListItem>Click the pencil icon to edit the service</ListItem>
        <ListItem>
          Red pause icon means service is active, click to deactivate
        </ListItem>
        <ListItem>
          Blue play icon means service is not active, click to activate
        </ListItem>
      </UnorderedList>

      <Box display="flex" justifyContent="flex-end" marginY="space60">
        <Flex>
          <Box
            display="flex"
            justifyContent="flex-end"
            marginY="space60"
            paddingRight="space60"
          >
            <Button variant="primary" onClick={() => navigate("add")}>
              <PlusIcon decorative={false} title="Add new service" />
              Add New Service
            </Button>
          </Box>
          <Box display="flex" justifyContent="flex-end" marginY="space60">
            <Button variant="secondary" onClick={exportTableData}>
              Download CSV
              <DownloadIcon decorative={false} title="download csv icon" />
            </Button>
          </Box>
        </Flex>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        marginY="space60"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Box
          display="flex"
          flexDirection="row"
          columnGap="space60"
          alignItems="flex-end"
        >
          <Box display="flex" flexDirection="column" width="15vw">
            <Label htmlFor="category">Category</Label>
            <Select id="category" name="category" onChange={handleFilterChange}>
              <Option value="All Categories">All Categories</Option>
              {categories && loaded
                ? categories &&
                  categories.map((s: any, index: number) => (
                    <Option key={index} value={s}>
                      {s}
                    </Option>
                  ))
                : null!}
            </Select>
          </Box>

          <Box display="flex" flexDirection="column" width="15vw">
            <Label htmlFor="language">Available Languages</Label>
            <Select id="language" name="language" onChange={handleFilterChange}>
              <Option value="All Languages">All Languages</Option>
              <Option value="English">English</Option>
              <Option value="Spanish">Spanish</Option>
            </Select>
          </Box>

          <Box display="flex" flexDirection="column" width="15vw">
            <Label htmlFor="connectionType">Available Connection Types</Label>
            <Select
              id="connectionType"
              name="connectionType"
              onChange={handleFilterChange}
            >
              <Option value="All Connection Types">All Connection Types</Option>
              <Option value="Phone">Phone</Option>
              <Option value="Online">Online</Option>
              <Option value="Video">Video</Option>
            </Select>
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end" height="fit-content">
          <Button variant="primary" onClick={applyFilters}>
            <FilterIcon decorative={false} title="Apply filters" />
            Apply
          </Button>
        </Box>
      </Box>

      {/*<Scroller />*/}

      <Table aria-label="Services table" striped>
        <THead>
          <Tr>
            <Th>Actions</Th>
            <Th>Category</Th>
            <Th>Service</Th>
            <Th>Available Languages</Th>
            <Th>Available Channels</Th>
            <Th>Routing Output Type</Th>
          </Tr>
        </THead>
        <TBody>
          {filteredServices && loaded ? (
            filteredServices &&
            filteredServices.map((service: any, rowIndex: number) => (
              <Tr key={rowIndex}>
                <Td>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    flexDirection="row"
                    columnGap="space60"
                  >
                    <Stack orientation="vertical" spacing="space60">
                      {service.isActive === 0 && (
                        <Button
                          variant="primary"
                          onClick={() =>
                            handleUndelete(service.serviceId, rowIndex)
                          }
                        >
                          <PlayIcon
                            decorative={false}
                            title="Service currently active"
                          />
                        </Button>
                      )}
                      {service.isActive === 1 && (
                        <Button
                          variant="destructive"
                          onClick={() =>
                            handleOpen(service.serviceId, rowIndex)
                          }
                        >
                          <PauseIcon
                            decorative={false}
                            title="Service currently inactive"
                          />
                        </Button>
                      )}
                      <Button
                        variant="secondary"
                        id={"edit-" + service.serviceId}
                        key={"k-edit-" + service.serviceId}
                        name={"n-edit-" + service.serviceId}
                        onClick={() =>
                          navigate(`/services/edit/${service.serviceId}`)
                        }
                      >
                        <EditIcon decorative={false} title="Edit Service" />
                      </Button>
                    </Stack>
                  </Box>
                </Td>
                <Td>{service.category}</Td>
                <Td>{service.name}</Td>
                <Td>{service.langs}</Td>
                <Td>{service.channels}</Td>
                <Td>
                  {service.routingOutputType.replace(
                    "RoundRobin",
                    "Round Robin",
                  )}
                </Td>
              </Tr>
            ))
          ) : (
            <TableSkeletonLoader numberOfTd={6} numberOfTr={1} />
          )}
        </TBody>
      </Table>
      <AlertDialog
        heading="Deactivate Service Line"
        isOpen={isOpen}
        destructive
        onConfirm={handleCloseWithDelete}
        onConfirmLabel="Delete"
        onDismiss={handleClose}
        onDismissLabel="Cancel"
      >
        Please confirm you want to deactivate this service.
        <HelpText variant="error">
          Warning - any migration of active services, or lines, will need to be
          carried out manually
        </HelpText>
      </AlertDialog>
    </Box>
  );
};
